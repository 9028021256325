import { Model } from "resourcerer";

export interface MetricsDateRange {
  start: string;
  end: string;
}

export default class DefaultMetricsDateRangeModel extends Model<MetricsDateRange> {
  parse(response: { start_date: string; end_date: string }) {
    return {
      start: response.start_date,
      end: response.end_date,
    };
  }

  url({ orgId }: { orgId: string }) {
    return `/api/organization/${orgId}/default-metrics-date-bounds`;
  }

  static dependencies = ["orgId", "property_token"];
}
