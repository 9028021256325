/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `URJANET` - Urjanet
 * * `ENERGY_STAR` - Energy Star
 * * `MANUAL_ENTRY` - Manual Entry
 */
export enum Source2c2Enum {
    URJANET = 'URJANET',
    ENERGY_STAR = 'ENERGY_STAR',
    MANUAL_ENTRY = 'MANUAL_ENTRY',
}
