/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Open Circuit` - Open Circuit
 * * `Closed Circuit` - Closed Circuit
 * * `Hybrid` - Hybrid
 */
export enum CoolingTowerTypeEnum {
    OPEN_CIRCUIT = 'Open Circuit',
    CLOSED_CIRCUIT = 'Closed Circuit',
    HYBRID = 'Hybrid',
}
