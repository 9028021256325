/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `HVAC Condenser Water Loop` - Hvac Condenser Water Loop
 * * `Geothermal Loop` - Geothermal Loop
 * * `Other` - Other
 */
export enum HeaterElectricSpecificHeatPumpWaterSourceEnum {
    HVAC_CONDENSER_WATER_LOOP = 'HVAC Condenser Water Loop',
    GEOTHERMAL_LOOP = 'Geothermal Loop',
    OTHER = 'Other',
}
