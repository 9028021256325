/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `electric` - Electricity
 * * `natural_gas` - Natural Gas
 * * `water` - Water
 * * `waste` - Waste
 * * `district_energy` - District Energy
 */
export enum MeterTypeEnum {
    ELECTRIC = 'electric',
    NATURAL_GAS = 'natural_gas',
    WATER = 'water',
    WASTE = 'waste',
    DISTRICT_ENERGY = 'district_energy',
}
