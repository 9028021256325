import type { MutableRefObject, ReactNode } from "react";

import { animated, useSpring } from "@react-spring/web";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

type PortalContext = { parent: HTMLElement | null };

// TODO: we can't use document.body as a default because document doesn't exist when this is
// first executed. this is a Next thing. Also NOTE: this is a ref, not an element, which allows us
// to reference an object instead of a value, so we don't need to worry about when the ref is
// attached (we don't need to pass a ref callback and store as state, we can just pass a ref).
export const PortalContext = createContext<{ parent: MutableRefObject<HTMLElement> }>({
  parent: null,
});
export const usePortalContext = () => useContext(PortalContext);

const DEFAULT_TIMEOUT = 200;

/**
 * Portal component that gives its children a nice fade in and out. It also uses the PortalContext,
 * which can define a parent for the component to "portal" within, which is really helpful for
 * preventing certain portaled elements from scrolling away from their anchor elements.
 */
export default function Portal({
  timeout = DEFAULT_TIMEOUT,
  className = "",
  ...props
}: {
  visible?: boolean;
  children: (() => ReactNode) | ReactNode;
  className?: string;
  timeout?: number;
  onUnmount?: () => void;
}) {
  const [isContainerMounted, setIsContainerMounted] = useState(false);
  const [shouldShowChildren, setShouldShowChildren] = useState(false);
  const containerRef = useRef<HTMLElement>(document.createElement("div"));
  const unmountRef = useRef<number>();
  const parent = usePortalContext().parent?.current || document.body;
  const springProps = useSpring({
    opacity: shouldShowChildren ? 1 : 0,
    config: { duration: timeout },
  });

  /**
   * Two sequential effects:
   *  1. When the visible prop is true, we mount the portal container. We can have many portals
   *     on the screen at once, but they will be added on-demand
   *  2. After the container is mounted, we can create the portal and transition in the children
   *
   * When the visible prop gets set to false, we do the opposite:
   *  1. We set isContainerMounted to false and transition out the children
   *  2. After the transition completes we can remove the container
   */
  useEffect(() => {
    if (props.visible) {
      if (unmountRef.current) {
        // clear any timeout currently in progress. don't return, though,
        // because we need the new callback when prop.visible turns false again
        window.clearTimeout(unmountRef.current);
        unmountRef.current = null;
        // this is in case we quickly toggle from true -> false -> true
        setShouldShowChildren(true);
      } else {
        containerRef.current.classList.add("Portal", ...(className ? [className] : []));
        parent.appendChild(containerRef.current);
        setIsContainerMounted(true);
      }

      return () => {
        setShouldShowChildren(false);

        unmountRef.current = window.setTimeout(() => {
          setIsContainerMounted(false);
          unmountRef.current = null;
          props.onUnmount?.();
          // enough time (with some buffer) for the portal children to transition out
        }, timeout + 50);
      };
    }
  }, [props.visible]);

  useEffect(() => {
    if (isContainerMounted) {
      setShouldShowChildren(true);

      return () => {
        if (parent.contains(containerRef.current)) {
          parent.removeChild(containerRef.current);
        }
      };
    }
  }, [isContainerMounted]);

  return isContainerMounted ?
      createPortal(
        <animated.div style={springProps}>
          {typeof props.children === "function" ? props.children() : props.children}
        </animated.div>,
        containerRef.current,
      )
    : null;
}
