/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Electricity` - Electricity
 * * `Fuels` - Fuels
 * * `District Energy` - District Energy
 * * `Other` - Other
 */
export enum UtilitySourcesCategoryEnum {
    ELECTRICITY = 'Electricity',
    FUELS = 'Fuels',
    DISTRICT_ENERGY = 'District Energy',
    OTHER = 'Other',
}
