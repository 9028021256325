/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `ADMIN` - Admin
 * * `ACCOUNT_HOLDER` - Account Holder
 * * `EMPLOYEE` - Employee
 * * `LIMITED_PARTNER` - Limited Partner
 */
export enum OrganizationMemberRoleEnum {
    ADMIN = 'ADMIN',
    ACCOUNT_HOLDER = 'ACCOUNT_HOLDER',
    EMPLOYEE = 'EMPLOYEE',
    LIMITED_PARTNER = 'LIMITED_PARTNER',
}
