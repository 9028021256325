import { Model } from "resourcerer";

import { Campus } from "@/Api/generated";

export default class CampusModel extends Model<Campus> {
  url({ orgToken, campusToken }: { orgToken: string; campusToken: string }) {
    return `/api/subportfolio/${orgToken}/campuses/${campusToken}`;
  }

  static dependencies = ["orgToken", "campusToken"];
}
