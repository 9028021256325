import type { Alignments, Positions } from "@/components/Hovercard";
import type { ForwardedRef, ReactElement, ReactNode } from "react";

import { cloneElement, forwardRef, useEffect, useImperativeHandle, useState } from "react";

import Hovercard from "@/components/Hovercard";

import { classnames } from "@/js/utils/cambio";

interface HovercardToggleProps {
  children: ReactElement;
  contents: ReactNode | (() => ReactNode);
  className?: string;
  hovercardClassName?: string;
  disabled?: boolean;
  alignment?: Alignments;
  position?: Positions;
  initiallyOpen?: boolean;
  onClose?: () => void;
}

export interface HovercardToggleHandle {
  open: () => void;
  close: () => void;
  getHovercardNode: () => HTMLElement;
}

/**
 * HovercardToggle is in charge of toggling a Hovercard open and closed when its trigger is clicked.
 * A future version can also toggle the hovercard based on an input focus. The actual Hovercard
 * hasn't yet been built, and right now this just nests the card within the HovercardToggle parent
 * element, which has its issues but is okay for now.
 */
const HovercardToggle = (
  {
    children,
    contents,
    disabled,
    alignment = "left",
    position = "bottom",
    className,
    ...props
  }: HovercardToggleProps,
  ref: ForwardedRef<HovercardToggleHandle>,
) => {
  const [open, setOpen] = useState(!!props.initiallyOpen);

  const [anchorNode, setAnchorNode] = useState<HTMLSpanElement>();
  const [hovercardNode, setHovercardNode] = useState<HTMLElement>();

  const isCloseableDescendant = (node: HTMLElement): boolean =>
    node === document.body ? false
    : ["BUTTON", "A"].includes(node.nodeName) ? true
    : isCloseableDescendant(node.parentElement);

  const close = () => {
    props.onClose?.();
    window.requestAnimationFrame(() => setOpen(false));
  };

  // the rafs here help ensure that any click handler that might invoke these don't
  // also invoke the onClickOutside handler here.
  useImperativeHandle(ref, () => ({
    open: () => window.requestAnimationFrame(() => setOpen(true)),
    close,
    getHovercardNode: () => hovercardNode,
  }));

  /**
   * Callbacks for auto-closing the hovercard toggle based on:
   *  * pressing the escape key
   *  * clicking outside the hovercard
   *  * clicking inside the hovercard on a CTA click (ie a button or anchor)
   */
  useEffect(() => {
    if (open) {
      let onClickOutside = (evt: MouseEvent) =>
        (
          // leave the trigger out of this
          !anchorNode?.firstChild.contains(evt.target as Node) &&
          ((hovercardNode && !hovercardNode.contains(evt.target as Node)) ||
            isCloseableDescendant(evt.target as HTMLElement))
        ) ?
          close()
        : null;

      let onKeyDown = (evt: KeyboardEvent) => {
        if (evt.key === "Escape") {
          close();
        }
      };

      document.addEventListener("click", onClickOutside);
      document.addEventListener("keydown", onKeyDown);

      return () => {
        document.removeEventListener("click", onClickOutside);
        document.removeEventListener("keydown", onKeyDown);
      };
    }
  }, [open, close, hovercardNode]);

  return (
    <span ref={setAnchorNode} className={classnames("HovercardToggle", className)}>
      {cloneElement(children, { disabled, onClick: !disabled ? () => setOpen(!open) : null })}
      <Hovercard
        ref={setHovercardNode}
        anchorEl={anchorNode}
        alignment={alignment}
        className={props.hovercardClassName}
        position={position}
        visible={open}
      >
        {contents}
      </Hovercard>
    </span>
  );
};

export default forwardRef(HovercardToggle);
