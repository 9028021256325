/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Hot Water plant year round` - Hot Water Year Round
 * * `Other indicated system carries off-season load` - Other System Off Season
 */
export enum HeaterDistrictEnergySpecificBuildingHeatingPlantUsageEnum {
    HOT_WATER_PLANT_YEAR_ROUND = 'Hot Water plant year round',
    OTHER_INDICATED_SYSTEM_CARRIES_OFF_SEASON_LOAD = 'Other indicated system carries off-season load',
}
