/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `market_based` - Market Based
 * * `location_based` - Location Based
 */
export enum CarbonAccountingTypeEnum {
    MARKET_BASED = 'market_based',
    LOCATION_BASED = 'location_based',
}
