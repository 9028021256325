/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `UTILITY_PROVIDER_INTEGRATION` - Utility Provider Integration
 * * `ENERGY_STAR_INTEGRATION` - Energy Star Integration
 * * `MANUAL_UPLOAD` - Manual Upload
 */
export enum ElectricityDataSourceEnum {
    UTILITY_PROVIDER_INTEGRATION = 'UTILITY_PROVIDER_INTEGRATION',
    ENERGY_STAR_INTEGRATION = 'ENERGY_STAR_INTEGRATION',
    MANUAL_UPLOAD = 'MANUAL_UPLOAD',
}
