import { Collection } from "resourcerer";

import { getPortfolioOrg } from "../core/coreResources";
import { Survey } from "./types";

export default class SurveysCollection extends Collection<Survey> {
  parse({ surveys }: { surveys: Survey[] }) {
    return surveys;
  }

  url() {
    return `/api/cambio_survey/${getPortfolioOrg().organization__token}/list-survey-with-partners`;
  }
}
