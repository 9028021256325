import { Model } from "resourcerer";

import { type FinesResponse } from "@/Api/generated";

export default class FinesModel extends Model<FinesResponse> {
  url({ orgId }: { orgId: string }) {
    return `/api/fines/organization/${orgId}`;
  }

  static dependencies = ["orgId", "space_token"];
}
