/**
 * Component we display when any of the core resources fail and the app can't load.
 */
export default function CambioDown() {
  return (
    <div className="CambioDown">
      <style>
        {`
          .CambioDown {
            align-items: center;
            display: flex;
            height: 100vh;
            justify-content: center;
            padding: 1rem;
            text-align: center;
            width: 100vw;

            > div {
              max-width: 38rem;
              width: 100%;
            }

            img {
              aspect-ratio: 1.73;
              width: 100%;
            }

            h1 {
              color: #040e1f;
              font-family: Inter;
              font-size: 2rem;
              font-weight: 500;
              line-height: normal;
              margin: 2rem 0 1.25rem;
            }

            p {
              font-size: 1rem;
              margin-bottom: 1.25rem;
            }

            a {
              border-radius: 3rem;
              background: #009088;
              color: #fff;
              display: inline-flex;
              height: 3rem;
              padding: 0.5rem 2rem;
              justify-content: center;
              align-items: center;
              gap: 0.5rem;
            }
          }
        `}
      </style>
      <div>
        <img src="/images/cambio-down.svg" />
        <h1>Cambio is under maintenance</h1>
        <p>Please try again in a few minutes or reach out to our team.</p>
        <a href="mailto:support@cambio.ai">Contact Support</a>
      </div>
    </div>
  );
}
