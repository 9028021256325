/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `CREATED` - Created
 * * `IN_PROGRESS` - In Progress
 * * `COMPLETED` - Completed
 * * `FAILED` - Failed
 */
export enum Status612Enum {
    CREATED = 'CREATED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
}
