/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `ACTIVE` - Active
 * * `IGNORED` - Ignored
 * * `IN_PROGRESS` - In Progress
 * * `RESOLVED` - Resolved
 */
export enum DataAnomalyAdminStateEnum {
    ACTIVE = 'ACTIVE',
    IGNORED = 'IGNORED',
    IN_PROGRESS = 'IN_PROGRESS',
    RESOLVED = 'RESOLVED',
}
