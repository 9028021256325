import { Collection } from "resourcerer";

export interface OrgSummary {
  energy_consumptions: Record<string, any>;
  image: string;
  name: string;
  organization_id: number;
  token: string;
  total_area: { size: number; unit: string };
  total_carbon: number;
}

export default class OrgSummariesCollection extends Collection<OrgSummary> {
  parse(response: { organization_summaries: OrgSummary[] }) {
    return response.organization_summaries;
  }

  url() {
    return "/api/organization/summaries";
  }

  static idAttribute = "token";
}
