import type { ClientEnhancedSpace } from "./PropertiesCollection";

import { Model } from "resourcerer";

import { RankingCategory } from "./PropertiesCollection";

export const NO_PROPERTY_TOKEN = "NO_PROPERTY";

export default class PropertyModel extends Model<ClientEnhancedSpace> {
  propertyRankingCategories: RankingCategory[];

  fetch(options = {}) {
    return Model.prototype.fetch.call(this, {
      ...options,
      // TODO: this is here to get around the non-RESTful endpoint. we can remove the fetch, the
      // parse, and the urlRoot methods when this endpoint is `/api/properties/{property_token}` and
      // it 404s when not found
      url: "/api/properties",
    }) as Promise<[this, Response]>;
  }

  parse(response: {
    properties: ClientEnhancedSpace[];
    property_ranking_categories: RankingCategory[];
  }) {
    this.propertyRankingCategories = response.property_ranking_categories;

    return {
      ...response.properties[0],
      token: response.properties[0]?.token || NO_PROPERTY_TOKEN,
    };
  }

  urlRoot() {
    return "";
  }

  static measure = true;

  static idAttribute = "token";

  static dependencies = ["organization_token", "spaces", "include_ranking"];
}
