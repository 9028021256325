/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `ASSET_MANAGER` - Asset Manager
 * * `PROPERTY_MANAGER` - Property Manager
 * * `WORKPLACE_OPERATIONS` - Workplace Operations
 * * `FACILITIES_MANAGER` - Facilities Manager
 * * `ACCOUNTS_PAYABLE` - Accounts Payable
 * * `SUSTAINABILITY` - Sustainability
 * * `LANDLORD_OTHER` - Landlord Other
 * * `TENANT_OTHER` - Tenant Other
 */
export enum OnboardingEntryRoleEnum {
    ASSET_MANAGER = 'ASSET_MANAGER',
    PROPERTY_MANAGER = 'PROPERTY_MANAGER',
    WORKPLACE_OPERATIONS = 'WORKPLACE_OPERATIONS',
    FACILITIES_MANAGER = 'FACILITIES_MANAGER',
    ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
    SUSTAINABILITY = 'SUSTAINABILITY',
    LANDLORD_OTHER = 'LANDLORD_OTHER',
    TENANT_OTHER = 'TENANT_OTHER',
}
