/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserMFAMethod } from '../models/UserMFAMethod';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static authActivateCreate({
        method,
    }: {
        method: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/{method}/activate/',
            path: {
                'method': method,
            },
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static authActivateConfirmCreate({
        method,
    }: {
        method: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/{method}/activate/confirm/',
            path: {
                'method': method,
            },
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static authCodesRegenerateCreate({
        method,
    }: {
        method: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/{method}/codes/regenerate/',
            path: {
                'method': method,
            },
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static authDeactivateCreate({
        method,
    }: {
        method: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/{method}/deactivate/',
            path: {
                'method': method,
            },
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static authCodeRequestCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/code/request/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static authLoginCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static authLoginCodeCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login/code/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static authMfaChangePrimaryMethodCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/mfa/change-primary-method/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static authMfaConfigRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/mfa/config/',
        });
    }

    /**
     * @returns UserMFAMethod
     * @throws ApiError
     */
    public static authMfaUserActiveMethodsList(): CancelablePromise<Array<UserMFAMethod>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/mfa/user-active-methods/',
        });
    }

}
