import { Collection } from "resourcerer";

import { PropertyCertificationGet } from "@/Api/generated/models/PropertyCertificationGet";

export default class CertificationsCollection extends Collection<PropertyCertificationGet> {
  parse(response: { certifications: PropertyCertificationGet[] }) {
    return response.certifications;
  }

  url({ subPortfolioToken, propertyToken }: { subPortfolioToken: string; propertyToken: string }) {
    return `/api/sub_portfolio/${subPortfolioToken}/property/${propertyToken}/certifications`;
  }

  static dependencies = ["subPortfolioToken", "propertyToken"];

  static idAttribute = "certification_type";
}
