/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Scroll` - Scroll
 * * `Screw` - Screw
 * * `Centrifugal` - Centrifugal
 */
export enum CoolerCompressorTypeEnum {
    SCROLL = 'Scroll',
    SCREW = 'Screw',
    CENTRIFUGAL = 'Centrifugal',
}
