/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `lbs/kWh` - Lbs Kwh
 * * `lbs/therm` - Lbs Therm
 * * `lbs/kBtu` - Lbs Kbtu
 */
export enum IndirectEmissionFactorDataViewSetUnitEnum {
    LBS_K_WH = 'lbs/kWh',
    LBS_THERM = 'lbs/therm',
    LBS_K_BTU = 'lbs/kBtu',
}
