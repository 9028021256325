import sortBy from "lodash/sortBy";
import { Model } from "resourcerer";

import { ScoreComparison } from "@/Api/generated";

interface Question {
  // this is "Large" for page-level header, and "default" for intra-page sections
  headerType?: string;
  type: string;
  // question title
  text: string;
  // this is how we link the question with the response
  order: string;
  options?: string;
  index?: number;
}

export interface Responses {
  [key: string]: {
    answer?: string | string[];
  };
}

interface Section extends Question {
  questions: Question[];
}

export interface SurveyPage extends Question {
  sections: Section[];
}
export interface AppendedDocument {
  name: string;
  text: string;
  answer: string[]; // file urls
  prettyFormat: string; // an html string which contains anchor tags that have properly formatted file names
}

export interface SurveyResult {
  survey_name: string;
  survey_year: number;
  token: string;
  partner: {
    token: string;
    first_name: string;
    last_name: string;
    company_name: string;
    email_address: string;
  };
  started_at: string;
  response_submitted_at: string | null;
  scores: ScoreComparison[];
  question_data: Record<string, Question> | null;
  response_data: { order?: string; answer?: string | string[] | Record<string, any> }[] | null;
  appended_documents: AppendedDocument[] | null;
  has_score: boolean;
  has_document: boolean;
}

interface ClientSurveyResult extends Omit<SurveyResult, "question_data" | "response_data"> {
  question_data: SurveyPage[];
  response_data: Responses | null;
}

const RenderableTypes = ["control_head", "control_textbox", "control_radio", "control_checkbox"];

export default class SurveyResultsModel extends Model<ClientSurveyResult> {
  parse(response: SurveyResult) {
    return {
      ...response,
      question_data: aggregateQuestionData(Object.values(response.question_data || {})),
      response_data:
        // this will be null if it hasn't been submitted yet
        // TODO: it turns out the responses are linked by question number, and we can just merge
        // them with the question_data in aggregateQuestionData, if wanted.
        response.response_data ?
          Object.values(response.response_data).reduce(
            (memo, entry) =>
              Object.assign(
                memo,
                // remove any answer properties that are objects, which have unknown schemas
                typeof entry.answer === "object" ?
                  { [entry.order]: { ...entry, answer: Object.values(entry.answer) } }
                : { [entry.order]: entry },
              ),
            {} as Responses,
          )
        : null,
    };
  }

  url({ survey_token, partner_token }: { survey_token: string; partner_token: string }) {
    return `/api/cambio_survey/${survey_token}/response/${partner_token}`;
  }

  static dependencies = ["survey_token", "partner_token", "year"];
}

/**
 * Takes a flattened array of survey headers and questions and turns it into a hierarchy
 * we can use to map through a DOM structure:
 *
 * Large control_head
 *   |
 *   |__default control_head
 *       |
 *       |__questions (control_{radio|checkbox|text})
 */
export function aggregateQuestionData(questions: Question[]): SurveyPage[] {
  let questionIndex = 0;

  return (
    sortBy(questions, ({ order }) => parseInt(order))
      .reduce((memo, question) => {
        if (!RenderableTypes.includes(question.type)) {
          return memo;
        } else if (question.type === "control_head") {
          if (question.headerType === "Large") {
            return memo.concat(question as SurveyPage);
          }

          return (memo.length ? memo : [{} as SurveyPage]).map((page, i) =>
            i !== Math.max(memo.length - 1, 0) ?
              page
            : { ...page, sections: (page.sections || []).concat(question as Section) },
          );
        }

        return memo.map((page, i) =>
          i !== memo.length - 1 ?
            page
          : {
              ...page,
              sections: (page.sections || [{} as Section]).map((section, j) =>
                j !== (page.sections?.length - 1 || 0) ?
                  section
                : {
                    ...section,
                    questions: (section.questions || []).concat({
                      ...question,
                      // this gives us the number in the bubble beside each question, because that
                      // isn't returned in a useful way from jotform. wait until after the first
                      // section to start counting because we remove the first (and last) section
                      // at the end of this function
                      ...(i ? { index: ++questionIndex } : {}),
                    }),
                  },
              ),
            },
        );
      }, [] as SurveyPage[])
      // remove first head option, which is just "tell me about yourself", and last section, which
      // is "you're all set!"
      .slice(1, -1)
  );
}
