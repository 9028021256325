/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Field erected (curtainwall, spandrel, etc.)` - Field Erected
 * * `Factory Produced (punched/framed openings)` - Factory Produced
 * * `Other` - Other
 */
export enum WindowTypeEnum {
    FIELD_ERECTED_CURTAINWALL_SPANDREL_ETC_ = 'Field erected (curtainwall, spandrel, etc.)',
    FACTORY_PRODUCED_PUNCHED_FRAMED_OPENINGS_ = 'Factory Produced (punched/framed openings)',
    OTHER = 'Other',
}
