import { Model } from "resourcerer";

import { SearchResult } from "@/Api/generated";

export default class SearchModel extends Model<{ results: SearchResult[] }> {
  fetch(options = {}) {
    return Model.prototype.fetch.call(this, {
      ...options,
      method: "POST",
      spoofBypass: true,
    }) as Promise<[this, Response]>;
  }

  url() {
    return "/api/omnisearch";
  }

  static dependencies = ["search_string", "search_types"];
}
