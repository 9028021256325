/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Primary` - Primary
 * * `Primary/Secondary` - Secondary
 * * `Primary/Secondary/Tertiary` - Tertiary
 */
export enum HydronicLoopTypeEnum {
    PRIMARY = 'Primary',
    PRIMARY_SECONDARY = 'Primary/Secondary',
    PRIMARY_SECONDARY_TERTIARY = 'Primary/Secondary/Tertiary',
}
