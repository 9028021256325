/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `ENERGY_STAR` - Energy Star
 */
export enum SourceFc0Enum {
    ENERGY_STAR = 'ENERGY_STAR',
}
