import dayjs from "dayjs";
import { Model } from "resourcerer";

import { IntervalDataResponse } from "@/Api/generated";

interface IntervalDataModel extends IntervalDataResponse {
  // in minutes
  interval: number;
}

export default class MeterIntervalDataModel extends Model<IntervalDataModel> {
  parse(response: IntervalDataResponse) {
    const inferredInterval = getInferredInterval(response.interval_data);

    return {
      ...response,
      // in minutes
      interval: Math.floor(inferredInterval / 60000),
      interval_data: fillInDataGaps(inferredInterval, response.interval_data),
    };
  }

  url({ propertyToken, meterId }: { propertyToken: string; meterId: number }) {
    return `/api/properties/${propertyToken}/meters/${meterId}/interval-data`;
  }

  static dependencies = ["propertyToken", "meterId", "start_date", "end_date"];
}

// in ms. Take the first 10 items, find intervals, use the lowest value
function getInferredInterval(data: IntervalDataResponse["interval_data"]): number {
  return Math.min(
    ...data
      .map(
        (entry, i) =>
          new Date(data[i + 1]?.start_date_time).valueOf() -
          new Date(entry.start_date_time).valueOf(),
      )
      // > 0 because when we hit daylight savings time between data points, we might have
      // a negative entry!
      .filter((interval) => interval && interval > 0),
  );
}

function fillInDataGaps(inferredInterval: number, data: IntervalDataResponse["interval_data"]) {
  return data.flatMap((entry, index) => {
    if (!data[index + 1]) {
      return [entry];
    }

    const currentTime = new Date(entry.start_date_time).valueOf();
    const nextTime = new Date(data[index + 1].start_date_time).valueOf();

    if (nextTime - currentTime === inferredInterval) {
      return [entry];
    }

    return Array.from(
      { length: Math.floor((nextTime - currentTime) / inferredInterval) },
      (_, i) => ({
        start_date_time: dayjs(currentTime + inferredInterval * i).toISOString(),
        end_date_time: dayjs(currentTime + inferredInterval * i - 1).toISOString(),
        data: null,
      }),
    );
  });
}
