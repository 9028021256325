/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `APP_USER` - APP_USER
 * * `PROPERTY_MANAGER` - PROPERTY_MANAGER
 */
export enum PersonaEnum {
    APP_USER = 'APP_USER',
    PROPERTY_MANAGER = 'PROPERTY_MANAGER',
}
