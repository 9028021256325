import { Collection } from "resourcerer";

interface UnitHistoryEntry {
  label: string;
  color: string;
  information_list?: Array<{ key: string; value: string }>;
  is_current: boolean;
}

export default class UnitHistoryCollection extends Collection<UnitHistoryEntry> {
  parse(response: { history: UnitHistoryEntry[] }) {
    return response.history;
  }

  url({ propertyToken, unitToken }: { propertyToken: string; unitToken: string }) {
    return `/api/property_space/${propertyToken}/unit/${unitToken}/history`;
  }

  static dependencies = ["propertyToken", "unitToken"];

  static idAttribute = "label";
}
