/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Dedicated` - Dedicated
 * * `Combined` - Combined
 */
export enum HeaterFlueAccessibilityEnum {
    DEDICATED = 'Dedicated',
    COMBINED = 'Combined',
}
