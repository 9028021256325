/**
 * This mirrors the "classnames" npm library. You can pass a string class or an object of strings
 * to booleans, and the ones with truthy values will get added to the class list. Returns a space-
 * separated string that can be added to any className prop.
 */
type classnameEntry = string | null | undefined | false | Record<string, boolean>;

export function classnames(...args: classnameEntry[]): string {
  return args
    .reduce(
      (names, entry) =>
        names.concat(
          !entry ? []
          : typeof entry === "string" ? entry.trim()
          : Object.keys(entry).reduce(
              (memo, name) => memo.concat(entry[name] ? name : []),
              [] as string[],
            ),
        ),
      [] as string[],
    )
    .join(" ");
}

// requestIdleCallback is still not implemented in Safari
export function requestIdleCallback(fn: () => void) {
  return typeof window.requestIdleCallback === "function" ?
      window.requestIdleCallback(fn)
    : window.setTimeout(fn, 0);
}

export function noop() {}

/**
 * Returns the value of a key or nested key in an object using a dot-separated string.
 * If at any level a key is undefined, the function returns undefined.
 *
 * @param obj - the object to search
 * @param keys - the key as a dot-separated string to search for
 * @returns the value of the key or nested key, or undefined if any key is undefined
 */
export function getNestedValue(obj: Record<string, any>, keys: string): any {
  if (obj === null || typeof obj !== "object") {
    return undefined;
  }

  const dotIndex = keys.indexOf(".");
  const firstKey = dotIndex === -1 ? keys : keys.slice(0, dotIndex);
  const remainingKeys = dotIndex === -1 ? "" : keys.slice(dotIndex + 1);

  if (!(firstKey in obj)) {
    return undefined;
  }

  if (dotIndex === -1) {
    return obj[firstKey];
  }

  return getNestedValue(obj[firstKey], remainingKeys);
}

/**
 * The inverse of the above function, in that set a value at nested property location in an object.
 * The key is a dot-separated string. It returns a new object.
 */
export function setNestedValue(obj: { [key: string]: any } = {}, key: string, val: any): any {
  const [first, ...rest] = key.split(".");

  return {
    ...obj,
    [first]: !rest.length ? val : setNestedValue(obj[first], rest.join("."), val),
  };
}
