import debounce from "lodash/debounce";
import { useMemo, useState } from "react";

/**
 * Useful for batching updates into a single re-render after the last update has been made. For
 * example, making network requests based on an input field. Instead of making a request for every
 * character added, we wait a little bit after the last character has been typed and make a single
 * request.
 */
export default function useDebouncedValue<T>(initialVal: T, delay: number = 250) {
  const [value, setValue] = useState<T>(initialVal);
  const debouncedSetValue = useMemo(() => debounce(setValue, delay), [delay]);

  return [value, debouncedSetValue] as const;
}
