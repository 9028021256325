import { Model } from "resourcerer";

export default class SpoofModel extends Model {
  save(attrs = {}, options = {}) {
    return Model.prototype.save.call(this, attrs, { ...options, spoofBypass: true }) as Promise<
      [this, Response]
    >;
  }

  url() {
    return "/api/impersonate";
  }
}

export class UnspoofModel extends Model {
  save(attrs = {}, options = {}) {
    return Model.prototype.save.call(this, attrs, { ...options, spoofBypass: true }) as Promise<
      [this, Response]
    >;
  }

  url() {
    return "/api/unimpersonate";
  }
}
