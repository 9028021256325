/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Demand Response` - Demand Response
 * * `System Temperature` - System Temperature
 * * `Constant Volume` - Constant Volume
 */
export enum HeaterRecirculationControlTypeEnum {
    DEMAND_RESPONSE = 'Demand Response',
    SYSTEM_TEMPERATURE = 'System Temperature',
    CONSTANT_VOLUME = 'Constant Volume',
}
