/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Variable Air Volume` - Vav
 * * `Fan Coil Unit` - Fcu
 * * `Radiators` - Radiators
 * * `Chilled Beams` - Chilled Beams
 * * `Constant Volume` - Cv
 * * `Baseboard` - Baseboard
 * * `Convector` - Convector
 * * `Radiant Floor` - Radiant Floor
 * * `Other` - Other
 */
export enum TerminalUnitTypeEnum {
    VARIABLE_AIR_VOLUME = 'Variable Air Volume',
    FAN_COIL_UNIT = 'Fan Coil Unit',
    RADIATORS = 'Radiators',
    CHILLED_BEAMS = 'Chilled Beams',
    CONSTANT_VOLUME = 'Constant Volume',
    BASEBOARD = 'Baseboard',
    CONVECTOR = 'Convector',
    RADIANT_FLOOR = 'Radiant Floor',
    OTHER = 'Other',
}
