import { classnames } from "@/js/utils/cambio";

type Flavors = "default" | "inline" | "overlay" | "overlay-only";

interface SpinnerProps {
  flavor?: Flavors;
}

export default function Spinner({ flavor = "default" }: SpinnerProps) {
  return <div className={classnames("Spinner", { [flavor]: flavor !== "default" })} />;
}
