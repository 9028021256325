/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `16 inches` - Inches 16
 * * `24 inches` - Inches 24
 * * `Other` - Other
 */
export enum WallFramingSpacingEnum {
    _16_INCHES = '16 inches',
    _24_INCHES = '24 inches',
    OTHER = 'Other',
}
