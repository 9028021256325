import { Collection } from "resourcerer";

import { PropertyMeters } from "@/Api/generated";

export interface Meter extends PropertyMeters {
  fraction_assigned: string;
}

export default class MetersCollection extends Collection<Meter> {
  parse({ meters }: { meters: Meter[] }) {
    return meters;
  }

  url({ spaceToken }: { spaceToken: string }) {
    return `/api/property_space/${spaceToken}/meters`;
  }

  static idAttribute = "meter_id";

  static dependencies = ["spaceToken", "include_unassigned_meters", "meter_data_type"];
}
