import type { Organization, User } from "@/js/models/types";

import { NextRouter, useRouter } from "next/router";
import { PropsWithChildren, ReactElement } from "react";

import { getCoreFeatureFlags, getEffectiveUser, getOrganizations } from "@/js/core/coreResources";
import { FeatureFlags } from "@/js/models/types";
import { isLoggedIn } from "@/js/utils/authentication";

import { PersonaEnum } from "@/Api/generated";
import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { hasFullPortfolioAccess } from "../utils/permissions";

type Gatekeeper<T = (props: any) => JSX.Element> = (Component: T) => T | null;

/**
 * Wrapper to turn any Gatekeeper function into a higher-order component, which is not
 * necessary but may be useful when combining multiple gatekeeper functions into one.
 */
const withGatekeeper =
  (Gatekeeper: any, options = {}): Gatekeeper =>
  (Component) =>
  (props) => (
    <Gatekeeper {...options}>
      <Component {...props} />
    </Gatekeeper>
  );

/**
 * Redirects to /signin if not logged in, or redirects to the app if logged in and on an
 * anonymous page like /signin or /forgot-password.
 */
export function RequiresLogin({
  invert,
  children,
}: { invert?: boolean } & { children: ReactElement }) {
  const router = useRouter();

  // this is an XOR: either (a) we are logged in and invert is true or
  // (b) we are not logged in and invert is false
  if (!isLoggedIn() !== !!invert) {
    router.replace(
      invert ? "/" : (
        `/signin${
          !/\/signin/.test(router.asPath) ? `?returnTo=${encodeURIComponent(router.asPath)}` : ""
        }`
      ),
    );

    return null;
  }

  return children;
}

export const withRequiresLogin = withGatekeeper(RequiresLogin);
export const withForbidsLogin = withGatekeeper(RequiresLogin, { invert: true });

interface RequiresFeatureOptions {
  feature: Uppercase<string>;
  test?: (val: any) => boolean;
  fallback?: string | ((router: NextRouter) => string);
}

/**
 * Requires a core (non-subportfolio or property-level feature). The default test mechanism is
 * truthiness, but that can be customized depending on the feature.
 */
export function RequiresFeature({
  feature,
  test = (val) => !!val,
  fallback = "/",
  children,
}: RequiresFeatureOptions & PropsWithChildren) {
  const router = useRouter();

  if (!test(getCoreFeatureFlags().get(feature))) {
    router.replace(typeof fallback === "function" ? fallback(router) : fallback);

    return null;
  }

  return children as ReactElement;
}

/**
 * Requires a subportfolio or property-level feature. This gatekeeper must be invoked within the
 * app context, above which we won't have access to the sub features. The default test mechanism is
 * truthiness, but that can be customized depending on the feature.
 */
export function RequiresSubFeature({
  feature,
  test = (val) => !!val,
  fallback = (router) => `/${router.query.organization_token}/dashboard`,
  children,
}: RequiresFeatureOptions & PropsWithChildren) {
  const router = useRouter();
  const { featureConfigurations } = useAppContext();

  if (!featureConfigurations) {
    throw new Error("RequiresSubFeature must be used within an AppContext");
  }

  if (feature && !test(featureConfigurations[feature])) {
    router.replace(typeof fallback === "function" ? fallback(router) : fallback);

    return null;
  }

  return children as ReactElement;
}

// we only default to custom redirect when we forbid a persona from entering.
// otherwise just send them home
const PersonaForbiddenFallbacks: Partial<Record<PersonaEnum, string>> = {
  PROPERTY_MANAGER: "/pm-onboarding",
};

interface RequiresPersonaOptions {
  persona: PersonaEnum;
  fallback?: string;
  invert?: boolean;
}

/**
 * Gatekeeper for keeping certain user personas away from some pages.
 */
export function RequiresPersona({
  persona,
  /** if this is true, then this becomes a "forbids persona" gatekeeper */
  invert,
  fallback = invert ? PersonaForbiddenFallbacks[persona] : "/",
  children,
}: RequiresPersonaOptions & PropsWithChildren) {
  const router = useRouter();

  // @ts-ignore TODO: persona will exist on user record, can then remove this
  if ((getEffectiveUser()?.get("persona") !== persona) ^ invert) {
    router.replace(fallback);

    return null;
  }

  return children as ReactElement;
}

export const withRequiresPersona = (
  options: RequiresPersonaOptions["persona"] | RequiresPersonaOptions,
) => withGatekeeper(RequiresPersona, typeof options === "string" ? { persona: options } : options);
export const withForbidsPersona = (
  options: RequiresPersonaOptions["persona"] | RequiresPersonaOptions,
) =>
  withGatekeeper(RequiresPersona, {
    ...(typeof options === "string" ? { persona: options } : options),
    invert: true,
  });

interface CustomGatekeeperTestParam {
  router: NextRouter;
  featureFlags: FeatureFlags;
  organizations: Organization[];
  user: User;
}

interface RequiresCustomOptions {
  test: (obj: CustomGatekeeperTestParam) => boolean;
  fallback?: string | ((obj: CustomGatekeeperTestParam) => string);
}

/**
 * This is a way for us to add custom one-of gatekeepers based on core resources. Each test function
 * will accept the router and our core resources, and if it fails the check, the fallback will get
 * invoked.
 */
export function RequiresCustom({
  test,
  fallback = "/",
  children,
}: RequiresCustomOptions & PropsWithChildren) {
  const router = useRouter();
  const testArgs: CustomGatekeeperTestParam = {
    router,
    featureFlags: getCoreFeatureFlags().toJSON(),
    organizations: getOrganizations().toJSON(),
    user: getEffectiveUser().toJSON(),
  };

  if (!test(testArgs)) {
    router.replace(typeof fallback === "function" ? fallback(testArgs) : fallback);

    return null;
  }

  return children;
}

export const withRequiresCustom = (options: RequiresCustomOptions) =>
  withGatekeeper(RequiresCustom, options);

export function RequiresFullPortfolioPermission({
  fallback = "/",
  children,
}: { fallback?: string } & PropsWithChildren) {
  const router = useRouter();

  if (
    getCoreFeatureFlags().get("TEMP_ORG_LEVEL_NEW_USER_PERMISSIONS_ENABLED") &&
    !hasFullPortfolioAccess()
  ) {
    router.replace(fallback);

    return null;
  }

  return children as ReactElement;
}
