/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `USD` - Usd
 */
export enum Currency91dEnum {
    USD = 'USD',
}
