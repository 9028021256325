import type { UtilitySpendingMetrics } from "@/Api/generated";

import { Model } from "resourcerer";

export default class UtilitySpendingModel extends Model<UtilitySpendingMetrics> {
  static idAttribute = "date";

  parse(response: UtilitySpendingMetrics) {
    return response;
  }

  url() {
    return "/api/properties/utility_spending_metrics";
  }

  static measure = true;

  static dependencies = [
    "organization_id",
    "spaces",
    "start_date",
    "end_date",
    "include_benchmark",
    "campus_token",
    "partner_tokens",
    "asset_class_broad_categories",
  ];
}
