import { Collection } from "resourcerer";

import { Address, Space } from "@/Api/generated";

export interface ClientSpace extends Omit<Space, "address"> {
  address: Partial<Address>;
}

export default class UnitsCollection extends Collection<ClientSpace> {
  url() {
    return "/api/spaces";
  }

  static idAttribute = "token";

  static dependencies = ["parent_space__token"];
}
