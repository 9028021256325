/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `NOT_STARTED` - Not Started
 * * `IN_PROGRESS` - In Progress
 * * `COMPLETE` - Complete
 * * `DECLINED` - Declined
 */
export enum OnboardingStatus332Enum {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
    DECLINED = 'DECLINED',
}
