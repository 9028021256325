/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Single Speed` - Single Speed
 * * `Two Speed` - Two Speed
 * * `VFD` - Vfd
 * * `ECM` - Ecm
 */
export enum CoolingTowerFanControlEnum {
    SINGLE_SPEED = 'Single Speed',
    TWO_SPEED = 'Two Speed',
    VFD = 'VFD',
    ECM = 'ECM',
}
