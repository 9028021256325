import { Component, ErrorInfo, PropsWithChildren } from "react";
import { ErrorState } from "@/components/EmptyState";

interface BoundaryProps extends PropsWithChildren {
  message?: string;
}

export default class ErrorBoundary extends Component<BoundaryProps, { hasError: boolean }> {
  constructor(props: BoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    window.Rollbar?.error(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorState message={this.props.message} />;
    }

    return this.props.children;
  }
}
