/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `weight_based` - Weight Based
 * * `volume_based` - Volume Based
 */
export enum InputUnitTypeEnum {
    WEIGHT_BASED = 'weight_based',
    VOLUME_BASED = 'volume_based',
}
