import type { ReactNode } from "react";
import type { PropertyTagType } from "../Tag/types";

import Link from "next/link";
import { useState } from "react";

import { PropertyTag } from "@/components/Tag";

import { DEFAULT_BUILDING_IMAGE_PATH } from "@/js/constants/cambio";
import { useScreenshotJobContext } from "@/js/hooks/useScreenshotContext";
import { classnames } from "@/js/utils/cambio";

// this is the old default image that gets set on a property automatically if another one isn't
// added. But we want to show a different image, so we'll swap it out with a prettier one.
const DEFAULT_IMG_NAME = /default_building_grey/;

interface PropertyCardProps {
  image?: string;
  hasLoaded?: boolean;
  href: string;
  name: string;
  details?: string;
  additionalContent?: ReactNode;
  tags?: PropertyTagType[];
}

/**
 * A card that shows a property image and some details. If the image fails,
 * we show the default image.
 */
export default function PropertyCard({ hasLoaded = true, tags = [], ...props }: PropertyCardProps) {
  return (
    <li className="PropertyCard">
      <section>
        <Link href={props.href}>
          {hasLoaded ?
            <PropertyImage src={props.image} />
          : null}
          {tags.length ?
            <ul className="tags-list">
              {tags.map((tagType) => (
                <li key={tagType}>
                  <PropertyTag type={tagType} />
                </li>
              ))}
            </ul>
          : null}
        </Link>
      </section>
      <header>
        <div>
          <h4>
            <a href={props.href}>{props.name}</a>
          </h4>
          {props.details ?
            <p>{props.details}</p>
          : null}
        </div>
        {props.additionalContent}
      </header>
    </li>
  );
}

/**
 * Handles rendering of default property images and fallbacks if the image assigned to a property
 * isn't valid.
 */
export function PropertyImage({ src }: { src: string }) {
  const [imgFetchHasErrored, setImgFetchHasErrored] = useState(false);
  const image = DEFAULT_IMG_NAME.test(src) ? DEFAULT_BUILDING_IMAGE_PATH : src;
  const { isImageValid } = useScreenshotJobContext();

  return (
    <img
      className={classnames("PropertyImage", {
        "default-image": imgFetchHasErrored || !src || DEFAULT_IMG_NAME.test(src),
      })}
      src={
        isImageValid === null ?
          image && !imgFetchHasErrored ?
            image
          : DEFAULT_BUILDING_IMAGE_PATH
        : isImageValid ?
          image
        : DEFAULT_BUILDING_IMAGE_PATH
      }
      alt="Property image"
      onError={() => setImgFetchHasErrored(true)}
      // This line is added to allow the image to be used in canvas operations (e.g., for screenshotting) without triggering a CORS error.
      crossOrigin="anonymous"
    />
  );
}
