/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Manual` - Manual
 * * `Occupancy Sensors` - Occupancy Sensors
 * * `Daylight Sensors` - Daylight Sensors
 * * `Time Clock` - Time Clock
 * * `BMS Integration` - Bms Integration
 * * `Other` - Other
 */
export enum ControlTypeEnum {
    MANUAL = 'Manual',
    OCCUPANCY_SENSORS = 'Occupancy Sensors',
    DAYLIGHT_SENSORS = 'Daylight Sensors',
    TIME_CLOCK = 'Time Clock',
    BMS_INTEGRATION = 'BMS Integration',
    OTHER = 'Other',
}
