/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `DX` - Dx
 * * `Chilled Water` - Chilled Water
 * * `Evaporative` - Evaporative
 */
export enum CoolerCoolingTypeEnum {
    DX = 'DX',
    CHILLED_WATER = 'Chilled Water',
    EVAPORATIVE = 'Evaporative',
}
