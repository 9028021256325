/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Centralized` - Centralized
 * * `Decentralized` - Decentralized
 */
export enum DomesticHotWaterSystemSystemTypeEnum {
    CENTRALIZED = 'Centralized',
    DECENTRALIZED = 'Decentralized',
}
