/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Cooling Tower` - Cooling Tower
 * * `Geothermal Loop` - Geothermal Loop
 * * `Boiler` - Boiler
 * * `Other` - Other
 */
export enum HeatPumpCondenserSourceTypeEnum {
    COOLING_TOWER = 'Cooling Tower',
    GEOTHERMAL_LOOP = 'Geothermal Loop',
    BOILER = 'Boiler',
    OTHER = 'Other',
}
