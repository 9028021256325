/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Shingles` - Shingles
 * * `Standing Seam metal roof` - Standing Seam Metal
 * * `Other` - Other
 */
export enum RoofPitchedMaterialEnum {
    SHINGLES = 'Shingles',
    STANDING_SEAM_METAL_ROOF = 'Standing Seam metal roof',
    OTHER = 'Other',
}
