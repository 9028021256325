/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `TENANT` - Tenant
 * * `LANDLORD` - Landlord
 */
export enum PropertyRelationshipEnum {
    TENANT = 'TENANT',
    LANDLORD = 'LANDLORD',
}
