import { getEffectiveUser, getPortfolioOrg } from "../core/coreResources";

export const hasFullPortfolioAccess = () => {
  const permissions = getEffectiveUser().get("permissions");

  return permissions?.some(
    (permission) =>
      permission.access_token === getPortfolioOrg().organization__token && permission.full_access,
  );
};

export const hasSubPortfolioAccess = (subPortfolioToken: string) => {
  const permissions = getEffectiveUser().get("permissions");

  return (
    hasFullPortfolioAccess() ||
    permissions?.some(
      (permission) => permission.access_token === subPortfolioToken && permission.full_access,
    )
  );
};

export const hasPropertyAccess = (propertyToken: string, subPortfolioToken: string) => {
  const permissions = getEffectiveUser().get("permissions");

  return (
    hasFullPortfolioAccess() ||
    hasSubPortfolioAccess(subPortfolioToken) ||
    permissions?.some(
      (permission) => permission.access_token === propertyToken && permission.full_access,
    )
  );
};
