import { getEffectiveUser, getPortfolioOrg } from "@/js/core/coreResources";
import { requestIdleCallback } from "@/js/utils/cambio";

import { isLoggedIn } from "../utils/authentication";

export class MixpanelTracking {
  private static _instance: MixpanelTracking;

  public static getInstance(): MixpanelTracking {
    if (!MixpanelTracking._instance) {
      return (MixpanelTracking._instance = new MixpanelTracking());
    }

    return this._instance;
  }

  public constructor() {
    if (MixpanelTracking._instance) {
      throw new Error(
        "Error: Instance Creation of Mixpanel Tracking is not allowed. Use Mixpanel.getInstance()",
      );
    }
  }

  public pageViewed(page = "") {
    track("Page Viewed", { page });
  }

  public loggedOut() {
    track("Logged Out");
    window.mixpanel?.reset();
  }

  public loggedIn(email: string) {
    window.mixpanel?.identify(email);
    window.mixpanel?.people.set({
      $username: email,
      $email: email,
    });
    track("Logged In");
  }
}

/**
 * Use this method anywhere and everywhere we want to make a call to mixpanel. Convention is that
 * names should be passive voice/past tense, ie "Logged out" or "Page viewed" or
 * "My Button clicked".
 */
export const track = (eventName: string, data = {}) => {
  const shouldMakeTrackCall =
    "mixpanel" in window &&
    ((!getEffectiveUser()?.get("is_staff") &&
      // our dashboard job is noisy in mixpanel. we can remove this when we have new pdf downloads
      getEffectiveUser()?.get("username") !== "cambiodashboardpdf") ||
      // pass a mixpanel=something in the URL to override, which will be useful in
      // ensuring things happen in production
      new URLSearchParams(window.location.search).get("mixpanel"));
  const portfolioOrg = getPortfolioOrg();

  if (shouldMakeTrackCall) {
    requestIdleCallback(
      () =>
        window.mixpanel?.track(eventName, {
          ...(isLoggedIn() ?
            {
              "Portfolio ID": portfolioOrg.organization__token,
              "Portfolio Name": portfolioOrg.organization__name,
            }
          : {}),
          ...data,
        }),
    );
  }
};
