import type { NavItem as NavItemType } from "@/js/constants/nav";

import Link from "next/link";

import SvgIcon from "@/components/SvgIcon";

import { classnames } from "@/js/utils/cambio";

/**
 * For consistent look/feel for nav links/buttons. Note that the Tag can also be an "a" instead of
 * Link component because when an item is disabled, we want to remove the `href` to make it
 * inaccessible, but the Link component requires an href.
 */
export default function NavItem(props: NavItemType & { selected?: boolean; locked?: boolean }) {
  const Tag =
    props.href ?
      !props.locked ?
        Link
      : "a"
    : "button";

  return (
    <li className={classnames("NavItem", { selected: props.selected })}>
      <Tag
        disabled={!!props.locked}
        // removing the href for an anchor (when locked) is how it gets disabled in HTML
        href={Tag === Link ? props.href : undefined}
        onClick={props.onClick}
      >
        {props.icon ?
          <SvgIcon name={props.icon} />
        : null}
        {props.name}
        {props.locked ?
          <SvgIcon name="lock" />
        : null}
      </Tag>
    </li>
  );
}
