import { MutableRefObject, useEffect, useLayoutEffect, useState } from "react";

import { classnames } from "../utils/cambio";

type OverflowingState = {
  top: boolean;
  bottom: boolean;
  left: boolean;
  right: boolean;
};

/**
 * This should be true if the "top" direction is on the bottom (ie flex-flow column-reverse).
 * We can do this for horizontal, as well, but I don't think we'll actually need it.
 */
interface OverflowOptions {
  reverseTop?: boolean;
}

/**
 * Attach a ref and pass it here to add a scroll listener so that we know when an element is
 * overflowing and can show js-powered overflow shadows and such.
 */
export default function useOverflow(
  ref: MutableRefObject<HTMLElement>,
  options: OverflowOptions = {} as OverflowOptions,
) {
  const [overflow, setOverflow] = useState<OverflowingState>({
    top: false,
    bottom: false,
    left: false,
    right: false,
  });

  const onScroll = () => {
    window.requestAnimationFrame(() => {
      const el = ref.current;
      const nextOverflow = {} as OverflowingState;

      // when we reverse, down is up, up is down
      const properties: Record<string, keyof OverflowingState> = {
        TOP: options.reverseTop ? "bottom" : "top",
        BOTTOM: options.reverseTop ? "top" : "bottom",
      };

      if (el) {
        el.scrollLeft ? (nextOverflow.left = true) : (nextOverflow.left = false);
        Math.floor(el.scrollTop) ?
          (nextOverflow[properties.TOP] = true)
        : (nextOverflow[properties.TOP] = false);
        el.scrollLeft < el.scrollWidth - el.offsetWidth ?
          (nextOverflow.right = true)
        : (nextOverflow.right = false);
        Math.ceil(Math.abs(el.scrollTop)) < el.scrollHeight - el.offsetHeight ?
          (nextOverflow[properties.BOTTOM] = true)
        : (nextOverflow[properties.BOTTOM] = false);

        if (
          Object.keys(overflow).some(
            (key) =>
              overflow[key as keyof OverflowingState] !==
              nextOverflow[key as keyof OverflowingState],
          )
        ) {
          setOverflow(nextOverflow);
        }
      }
    });
  };

  useLayoutEffect(onScroll, []);

  useEffect(() => {
    window.addEventListener("resize", onScroll);

    return () => window.removeEventListener("resize", onScroll);
  }, [overflow]);

  return {
    overflow,
    onScroll,
    overflowClassNames: classnames({
      "overflow-right": overflow.right,
      "overflow-left": overflow.left,
      "overflow-top": overflow.top,
      "overflow-bottom": overflow.bottom,
    }),
  };
}
