/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Pitched` - Pitched
 * * `Flat Roof System` - Flat Roof System
 * * `Other` - Other
 */
export enum RoofSystemTypeEnum {
    PITCHED = 'Pitched',
    FLAT_ROOF_SYSTEM = 'Flat Roof System',
    OTHER = 'Other',
}
