import omit from "lodash/omit";
import { useRouter } from "next/router";
import { useLayoutEffect, useState } from "react";

import { getPortfolioOrg, getRealUser } from "@/js/core/coreResources";
import SpoofModel from "@/js/models/SpoofModel";

/**
 * This hook will use the first query param it sees. We might, in the future, use `u` for spoofing
 * a specific user, among other things.
 */
const SpoofParams = [
  {
    key: "o",
    apiProperty: "portfolio_token",
    getExistingValue: () => getPortfolioOrg().organization__token,
  },
];

/**
 * Makes a request to spoof based on the presence of a url query parameter. Afterwards removes the
 * query param and refreshes the page. If we error, we just remove the query param.
 */
export default function useAutoSpoofing(shouldSpoof?: boolean) {
  /** This is fetching state so that a client component can have some feedback */
  const [requestingToSpoof, setRequestingToSpoof] = useState(false);
  const router = useRouter();

  useLayoutEffect(() => {
    if (shouldSpoof && getRealUser()?.get("is_staff")) {
      const searchParams = new URLSearchParams(window.location.search);
      // takes the first found spoof query param
      const spoofParam = SpoofParams.find(({ key }) => searchParams.has(key));

      if (spoofParam && searchParams.get(spoofParam.key) !== spoofParam.getExistingValue()) {
        const removeParamFromUrl = async () =>
          router.replace({ query: omit(router.query, spoofParam.key) }, undefined, {
            shallow: true,
          });

        setRequestingToSpoof(true);

        new SpoofModel()
          .save({ [spoofParam.apiProperty]: searchParams.get(spoofParam.key) })
          .then(async () => {
            await removeParamFromUrl();
            window.location.reload();
          })
          .catch(async () => {
            setRequestingToSpoof(false);
            await removeParamFromUrl();
          });
      }
    }
  }, [shouldSpoof]);

  return [requestingToSpoof];
}
