import { Collection } from "resourcerer";

import { PropertyFilter, PropertyFilterList } from "@/Api/generated";

export default class PropertyFiltersCollection extends Collection<PropertyFilter> {
  parse(response: PropertyFilterList) {
    return response.property_filters;
  }

  url({ orgToken }: { orgToken: string }) {
    return `/api/organization/${orgToken}/property-filter-options`;
  }

  static idAttribute = "filter_key";

  static dependencies = ["orgToken"];
}
