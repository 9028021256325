/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `CAMPUS` - Campus
 * * `PARTIAL_CAMPUS` - Partial Campus
 * * `PROPERTY` - Property
 * * `UNIT` - Unit
 * * `NO_PRORATA` - No Prorata
 */
export enum ProrataLevelEnum {
    CAMPUS = 'CAMPUS',
    PARTIAL_CAMPUS = 'PARTIAL_CAMPUS',
    PROPERTY = 'PROPERTY',
    UNIT = 'UNIT',
    NO_PRORATA = 'NO_PRORATA',
}
