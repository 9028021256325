/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Landlord` - Landlord
 * * `Tenant` - Tenant
 */
export enum OrganizationTypeEnum {
    LANDLORD = 'Landlord',
    TENANT = 'Tenant',
}
