/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `HEATING` - Heating
 * * `COOLING` - Cooling
 * * `COVEYANCE` - Envelope
 * * `DOMESTIC_HOT_WATER` - Domestic Hot Water
 * * `LIGHTING` - Lighting
 * * `PLUG_LOADS_AND_MISC` - Plug Loads And Misc
 * * `PROCESS_LOADS` - Process Loads
 * * `VENTILATION` - Ventilation
 */
export enum RetrofitEnergyImpactAnalysisSystemTypeEnum {
    HEATING = 'HEATING',
    COOLING = 'COOLING',
    COVEYANCE = 'COVEYANCE',
    DOMESTIC_HOT_WATER = 'DOMESTIC_HOT_WATER',
    LIGHTING = 'LIGHTING',
    PLUG_LOADS_AND_MISC = 'PLUG_LOADS_AND_MISC',
    PROCESS_LOADS = 'PROCESS_LOADS',
    VENTILATION = 'VENTILATION',
}
