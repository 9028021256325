/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Air Source` - Air Source
 * * `Water Source` - Water Source
 * * `Geothermal` - Geothermal
 */
export enum HeatPumpTypeEnum {
    AIR_SOURCE = 'Air Source',
    WATER_SOURCE = 'Water Source',
    GEOTHERMAL = 'Geothermal',
}
