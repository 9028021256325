/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Condensing` - Condensing
 * * `Non-condensing` - Non Condensing
 */
export enum HeaterBurnerTypeEnum {
    CONDENSING = 'Condensing',
    NON_CONDENSING = 'Non-condensing',
}
