import { Model } from "resourcerer";

interface Footprint {
  area: number;
  unit: string;
}

export default class TotalFootprintModel extends Model<Footprint> {
  url() {
    return "/api/properties/total_footprint";
  }

  parse({ unit, area }: { area: number; unit: string }) {
    return {
      unit,
      area,
    };
  }

  static dependencies = [
    "start_date",
    "end_date",
    "organization_id",
    "spaces",
    "campus_token",
    "partner_tokens",
    "asset_class_broad_categories",
  ];
}
