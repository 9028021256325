import { Model, ModelCache } from "resourcerer";

import { type User } from "@/js/models/types";

export default class UserModel extends Model<User> {
  parse({ is_spoofing, ...response }: User & { is_spoofing?: boolean; real_user?: User }) {
    /**
     * When not spoofing, the real user will be the same as the effective user. When spoofing,
     * the real user will be the cambio admin, and will get returned as part of the load_user
     * response.
     * TODO: real user should be made up of other properties in this response. This part of the
     * API isn't actually designed yet because we aren't doing session-based spoofing.
     * this is what we will ultimately want to set as the real user model:
     * is_spoofing ? new RealUserModel({ ...response.real_user, is_spoofing }) : this,
     */
    ModelCache.put("realUser", new RealUserModel({ ...response, is_spoofing }), {});

    return response;
  }

  static measure = true;

  url() {
    return "/api/account/load_user";
  }
}

export class RealUserModel extends Model<
  User & {
    is_spoofing?: boolean;
  }
> {}
