import { Model } from "resourcerer";

import { GetOrganizationSurveyScoreSuccessResponse, ScoreComparison } from "@/Api/generated";

interface SurveyScore {
  scores: ScoreComparison[];
  name: string;
  year: number;
}

/**
 * We have a few different survey-related models. This one is for furnishing scores to the
 * SurveyScoreCard on the dashboard pages.
 */
export default class SurveyScoreModel extends Model<SurveyScore> {
  parse(response: GetOrganizationSurveyScoreSuccessResponse) {
    return {
      scores: response.score_comparison,
      name: response.survey_name,
      year: response.survey_year,
    };
  }

  url({ orgToken }: { orgToken: string }) {
    return `/api/organization/${orgToken}/survey-score`;
  }

  static dependencies = ["year", "property_token", "orgToken", "partner_tokens"];
}
