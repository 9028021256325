/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Centralized` - Centralized
 * * `Distributed` - Distributed
 */
export enum HeaterDistributionEnum {
    CENTRALIZED = 'Centralized',
    DISTRIBUTED = 'Distributed',
}
