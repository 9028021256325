import type { CrremStrandingAnalysis } from "@/Api/generated";

import { Model } from "resourcerer";

export default class CrremStrandingAnalysisModel extends Model<CrremStrandingAnalysis> {
  fetch(options = {}) {
    return Model.prototype.fetch.call(this, {
      ...options,
      method: "POST",
      spoofBypass: true,
    }) as Promise<[this, Response]>;
  }

  url({ propertyToken }: { propertyToken: string }) {
    return `/api/property/${propertyToken}/crrem/stranding-analysis`;
  }

  static dependencies = ["propertyToken"];
}
