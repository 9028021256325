/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Electric Resistance` - Electric Resistance
 * * `Heat Pump` - Heat Pump
 */
export enum HeaterElectricSpecificHeatSourceEnum {
    ELECTRIC_RESISTANCE = 'Electric Resistance',
    HEAT_PUMP = 'Heat Pump',
}
