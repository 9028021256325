/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `CAMBIO_APPLICATION` - Cambio Application
 * * `MANUAL_EDIT` - Manual Edit
 * * `ECM_APPLICABILITY` - Workflow Ecm Applicability
 * * `SYSTEM_INVENTORY_GENERATION` - Workflow System Inventory Generation
 */
export enum SourceA9bEnum {
    CAMBIO_APPLICATION = 'CAMBIO_APPLICATION',
    MANUAL_EDIT = 'MANUAL_EDIT',
    ECM_APPLICABILITY = 'ECM_APPLICABILITY',
    SYSTEM_INVENTORY_GENERATION = 'SYSTEM_INVENTORY_GENERATION',
}
