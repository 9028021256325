/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `LANDLORD` - Landlord
 * * `TENANT` - Tenant
 */
export enum OrganizationRelationshipEnum {
    LANDLORD = 'LANDLORD',
    TENANT = 'TENANT',
}
