import { ModelCache } from "resourcerer";

import { type Organization } from "@/js/models/types";

import FeatureFlagsModel from "../models/featureFlagsModel";
import OrganizationsCollection from "../models/organizationsCollection";
import UserModel, { RealUserModel } from "../models/UserModel";

// because these are core resources, we can use them anywhere in our app
// and know that they will exist
export const getRealUser = () => ModelCache.get("realUser") as RealUserModel;
export const getEffectiveUser = () => ModelCache.get("user") as UserModel;
export const getOrganizations = () => ModelCache.get("organizations") as OrganizationsCollection;
export const getCoreFeatureFlags = () => ModelCache.get("coreFeatureFlags") as FeatureFlagsModel;

export function getPortfolioOrg(organizations?: Organization[]): Organization {
  const orgs = organizations || getOrganizations()?.toJSON();

  return orgs?.find((org) => org.organization_level === "PORTFOLIO") || ({} as Organization);
}

export function getSubportfolios(): Organization[] {
  return getOrganizations()
    .toJSON()
    .filter((org) => org.organization_level === "SUB_PORTFOLIO");
}
