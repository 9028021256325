/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `CREATED` - Created
 * * `PROCESSING` - Processing
 * * `FAILED` - Failed
 * * `COMPLETED` - Completed
 */
export enum JobStateEnum {
    CREATED = 'CREATED',
    PROCESSING = 'PROCESSING',
    FAILED = 'FAILED',
    COMPLETED = 'COMPLETED',
}
