import type { IconsType } from "@/components/SvgIcon";
import type { ForwardedRef, MouseEventHandler, ReactNode } from "react";

import Link from "next/link";
import { forwardRef } from "react";

import SvgIcon from "@/components/SvgIcon";

import { classnames } from "@/js/utils/cambio";

export type ButtonElement = HTMLButtonElement & HTMLAnchorElement;
type ButtonFlavors = "primary" | "secondary" | "link";

export interface ButtonProps {
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  form?: string;
  label?: string;
  onClick?: MouseEventHandler;
  href?: string;
  icon?: IconsType;
  flavor?: ButtonFlavors;
  /** In case we need to override default roles */
  role?: string;
  size?: "small" | "medium";
  target?: "_blank";
  type?: "button" | "submit" | "reset";
}

/**
 * Our main button component. It is polymorphic in that it will automatically render either an
 * anchor tag or a button tag depending on the presence of an `href`. This will evolve over time,
 * as well, to include more flavors of buttons. This component will keep all of them consistent.
 */
const Button = (
  {
    className = "",
    flavor = "secondary",
    href,
    size = "medium",
    type = "button",
    ...props
  }: ButtonProps,
  ref: ForwardedRef<ButtonElement>,
) => {
  const Tag = href ? Link : "button";

  return (
    <Tag
      ref={ref}
      aria-label={props.label}
      className={classnames("Button", className, {
        [flavor]: flavor !== "secondary",
        [size]: size !== "medium",
      })}
      disabled={props.disabled}
      form={props.form}
      href={href}
      {...(href && props.target ? { target: props.target } : {})}
      role={props.role}
      onClick={props.onClick}
      type={Tag === "button" ? type : undefined}
    >
      {props.icon ?
        <SvgIcon name={props.icon} />
      : null}
      {props.children}
    </Tag>
  );
};

export default forwardRef(Button);
