/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Single Pane` - Single Pane
 * * `Double Glazed` - Double Glazed
 * * `Triple Glazed` - Triple Glazed
 * * `Over triple Glazed` - Over Triple Glazed
 * * `Vacuum Insulated Windows` - Vacuum Insulated
 * * `Other` - Other
 */
export enum WindowGlazingTypeEnum {
    SINGLE_PANE = 'Single Pane',
    DOUBLE_GLAZED = 'Double Glazed',
    TRIPLE_GLAZED = 'Triple Glazed',
    OVER_TRIPLE_GLAZED = 'Over triple Glazed',
    VACUUM_INSULATED_WINDOWS = 'Vacuum Insulated Windows',
    OTHER = 'Other',
}
