/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `SOLD` - Sold
 * * `LEASE_ENDED` - Lease Ended
 * * `OTHER` - Other
 */
export enum ReasonEnum {
    SOLD = 'SOLD',
    LEASE_ENDED = 'LEASE_ENDED',
    OTHER = 'OTHER',
}
