/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `CARBON_REDUCTION` - Carbon Reduction
 * * `RENEWABLE` - Renewable
 */
export enum Type66eEnum {
    CARBON_REDUCTION = 'CARBON_REDUCTION',
    RENEWABLE = 'RENEWABLE',
}
