import { Model } from "resourcerer";

import { UnitTypeEnum } from "@/Api/generated";

export interface PropertySummary {
  unit_type: string;
  type: string;
  total_area: number;
  total_onboarded_area: number;
  percentage_complete: number;
}

export interface EmissionSummary {
  name: string;
  area: number;
  scope: string;
  percentage_complete: number;
}

export interface PropertyDetails {
  property_id: number;
  name: string;
  emission_summary: Array<EmissionSummary>;
  summary: Array<PropertySummary>;
  units: Array<{
    token: string;
    name: string;
    type: UnitTypeEnum;
    area: number;
    scope: string;
    status: string;
    start_date?: string;
    end_date?: string;
  }>;
}

export interface OnboardingSummary {
  summary: {
    total_area: number;
    percentage_complete: number;
    direct_emissions_percentage_complete: number;
    indirect_emissions_percentage_complete: number;
  };
  aggregate_property_details: Array<{
    property_name: string;
    area: number;
    direct_emissions_percentage_onboarded: number;
    indirect_emissions_percentage_onboarded: number;
    total_percentage_onboarded: number;
  }>;
  property_details: Array<PropertyDetails>;
}

export default class OnboardingSummaryModel extends Model<OnboardingSummary> {
  url() {
    return "/api/onboarding/summary";
  }

  static dependencies = [
    "organization_id",
    "space_id",
    "campus_token",
    "partner_tokens",
    "asset_class_broad_categories",
  ];
}
