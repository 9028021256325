/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `STARTED` - Started
 * * `SUBMITTED` - Submitted
 */
export enum State2ceEnum {
    STARTED = 'STARTED',
    SUBMITTED = 'SUBMITTED',
}
