import type { RetrofitCarbonMitigationForecast } from "@/Api/generated";

import { Model } from "resourcerer";

export default class CarbonMitigationForecastModel extends Model<RetrofitCarbonMitigationForecast> {
  url({ propertyToken }: { propertyToken: string }) {
    return `/api/retrofit-carbon-mitigation-forecast/${propertyToken}`;
  }

  static dependencies = ["propertyToken"];
}
