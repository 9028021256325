/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `1` - One
 * * `2` - Two
 * * `3` - Three
 */
export enum ScopeEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
}
