/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `HEATING` - Heating
 * * `COOLING` - Cooling
 * * `ENVELOPE` - Envelope
 * * `DOMESTIC_HOT_WATER` - Domestic Hot Water
 * * `LIGHTING` - Lighting
 * * `CONTROLS` - Controls
 */
export enum RetrofitProjectSystemTypeEnum {
    HEATING = 'HEATING',
    COOLING = 'COOLING',
    ENVELOPE = 'ENVELOPE',
    DOMESTIC_HOT_WATER = 'DOMESTIC_HOT_WATER',
    LIGHTING = 'LIGHTING',
    CONTROLS = 'CONTROLS',
}
