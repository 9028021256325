import { useLayoutEffect } from "react";

export default function useInertBody(dependency: boolean, duration: number = 600) {
  /**
   * When we launch a modal or a side panel, we hide body overflow so that it can't scroll underneath.
   * But that also means scrollbars disappear, which can create an unsightly layout shift. This effect
   * freezes the body width while the overlay is present and creates a focus trap for the modal/panel.
   */
  useLayoutEffect(() => {
    if (dependency) {
      document.body.style.width = getComputedStyle(document.body).width;
      document.querySelector("#__next")?.setAttribute("inert", "");

      return () => {
        window.setTimeout(() => {
          document.querySelector("#__next")?.removeAttribute("inert");
          document.body.style.width = "";
          // give it enough time for the animation to complete
        }, duration);
      };
    }
  }, [dependency]);
}
