/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `White or light color shade` - White Light
 * * `Black or dark color shade` - Black Dark
 * * `Other` - Other
 */
export enum RoofColorEnum {
    WHITE_OR_LIGHT_COLOR_SHADE = 'White or light color shade',
    BLACK_OR_DARK_COLOR_SHADE = 'Black or dark color shade',
    OTHER = 'Other',
}
