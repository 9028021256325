import type { MetricsValues, PrecomputedPropertyMetrics, PropertyMetrics } from "@/Api/generated";
import type { Unit } from "@/js/types/utils";

import { Collection } from "resourcerer";

interface ClientPropertyMetrics extends Omit<PropertyMetrics, "metrics"> {
  metrics: Record<string, MetricsValues>;
}

export default class PropertyMetricsCollection extends Collection<ClientPropertyMetrics> {
  units: Record<string, Unit>;

  parse(response: PrecomputedPropertyMetrics) {
    this.units = response.metric_units.reduce(
      (memo, { type, value }) => Object.assign(memo, { [type]: value }),
      {},
    );

    return (response.properties || []).map((property) => ({
      ...property,
      metrics: property.metrics.reduce(
        (memo, entry) => Object.assign(memo, { [entry.type]: entry }),
        {},
      ),
    }));
  }

  url() {
    return "/api/properties/metrics-summary";
  }

  static dependencies = ["organization_token", "start_date", "end_date"];

  static idAttribute = "token";
}
