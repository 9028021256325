/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `solar` - Solar
 * * `wind` - Wind
 * * `other` - Other
 * * `district_steam` - District Steam
 * * `district_chilled_water` - District Chilled Water
 * * `district_hot_water` - District Hot Water
 * * `disposed_trash` - Disposed Trash
 * * `disposed_mixed_recyclable` - Disposed Mixed Recyclable
 * * `recycled` - Recycled
 * * `recycled_batteries` - Recycled Batteries
 * * `recycled_electronics` - Recycled Electronics
 * * `recycled_pallets` - Recycled Pallets
 * * `recycled_appliances` - Recycled Appliances
 * * `recycled_fats_oils_grease` - Recycled Fats Oils Grease
 * * `composted_food_scraps` - Waste Composted Food Food Scraps
 * * `disposed_lamps_light_bulbs` - Waste Disposed Lamps Light Bulbs
 * * `water_mixed_indoor_outdoor` - Water Other Mixed Indoor Outdoor
 * * `composted` - Composted
 * * `fuel_oil_no_2` - Fuel Oil No 2
 * * `propane` - Fuel Propane
 */
export enum SubtypeEnum {
    SOLAR = 'solar',
    WIND = 'wind',
    OTHER = 'other',
    DISTRICT_STEAM = 'district_steam',
    DISTRICT_CHILLED_WATER = 'district_chilled_water',
    DISTRICT_HOT_WATER = 'district_hot_water',
    DISPOSED_TRASH = 'disposed_trash',
    DISPOSED_MIXED_RECYCLABLE = 'disposed_mixed_recyclable',
    RECYCLED = 'recycled',
    RECYCLED_BATTERIES = 'recycled_batteries',
    RECYCLED_ELECTRONICS = 'recycled_electronics',
    RECYCLED_PALLETS = 'recycled_pallets',
    RECYCLED_APPLIANCES = 'recycled_appliances',
    RECYCLED_FATS_OILS_GREASE = 'recycled_fats_oils_grease',
    COMPOSTED_FOOD_SCRAPS = 'composted_food_scraps',
    DISPOSED_LAMPS_LIGHT_BULBS = 'disposed_lamps_light_bulbs',
    WATER_MIXED_INDOOR_OUTDOOR = 'water_mixed_indoor_outdoor',
    COMPOSTED = 'composted',
    FUEL_OIL_NO_2 = 'fuel_oil_no_2',
    PROPANE = 'propane',
}
