/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Peak Domestic Hot Water Demand Period` - Peak Domestic Hot Water Demand Period
 * * `Low Temperature Outside Air Conditions` - Low Temperature Outside Air Conditions
 * * `Both` - Both
 * * `Unknown` - Unknown
 */
export enum HeaterElectricSpecificHybridElectricResistanceDesignEnum {
    PEAK_DOMESTIC_HOT_WATER_DEMAND_PERIOD = 'Peak Domestic Hot Water Demand Period',
    LOW_TEMPERATURE_OUTSIDE_AIR_CONDITIONS = 'Low Temperature Outside Air Conditions',
    BOTH = 'Both',
    UNKNOWN = 'Unknown',
}
