import "@/js/core/globalFetch";
import "@/js/core/resourcererConfig";
import "@/js/core/spoofGuard";

import type { PropsWithChildren, ReactElement, ReactNode } from "react";

import { NextPage } from "next";
import { AppProps } from "next/app";
import { useEffect, useLayoutEffect } from "react";

import "@/styles/cambio.css";

import type { ExecutorFunction } from "resourcerer";

import dynamic from "next/dynamic";
import { useResources } from "resourcerer";

import ErrorBoundary from "@/components/ErrorBoundary";

import { loadAnalytics } from "@/js/core/analytics";
import CambioDown from "@/js/core/CambioDown";
import { getPortfolioOrg } from "@/js/core/coreResources";
import useAutoSpoofing from "@/js/hooks/useAutoSpoofing";
import useStorageEvent from "@/js/hooks/useStorageEvent";
import { track } from "@/js/services/mixpanel";
import { isLoggedIn } from "@/js/utils/authentication";

import { PersonaEnum } from "@/Api/generated";
import AppLayout from "@/layouts/AppLayout";
import SpoofBanner from "@/layouts/AppLayout/SpoofBanner";

export type PageWithLayout<P = NonNullable<unknown>, IP = P> = NextPage<P, IP> & {
  Layout?: ({ children }: PropsWithChildren) => JSX.Element;
  getLayout?: (page: ReactElement) => ReactNode;
};

// load in our core resources
const getResources: ExecutorFunction<
  "organizations" | "coreFeatureFlags" | "user",
  { persona: PersonaEnum; portfolioId?: string }
> = (props) =>
  isLoggedIn() ?
    {
      ...(props.persona === PersonaEnum.APP_USER ?
        {
          organizations: {
            provides: (collection) => ({
              portfolioId: getPortfolioOrg(collection.toJSON()).organization__token,
            }),
          },
          coreFeatureFlags: {
            params: { organization_token: props.portfolioId },
            dependsOn: !!props.portfolioId,
          },
        }
      : { coreFeatureFlags: { dependsOn: !!props.persona } }),
      user: { provides: (model) => ({ persona: model.get("persona") }) },
    }
  : { coreFeatureFlags: {} };

function App({ Component, pageProps }: AppProps & { Component: PageWithLayout }) {
  const Layout = Component.Layout || AppLayout;
  const { coreFeatureFlagsModel, hasLoaded, hasErrored } = useResources(getResources, pageProps);
  const [requestingToSpoof] = useAutoSpoofing(hasLoaded);

  useLayoutEffect(() => {
    if (hasLoaded || hasErrored) {
      document.querySelector("#cambio-loader")?.remove();
    }
  }, [hasLoaded, hasErrored]);

  /**
   * Global listeners/hooks go here
   */
  useEffect(() => {
    loadAnalytics();
  }, []);

  /**
   *
   * Keeps all Cambio tabs in sync when a user logs in or out.
   */
  useStorageEvent("accessData", (evt) => {
    if (evt.oldValue && !evt.newValue) {
      window.location.assign("/signin");
    } else if (!evt.oldValue && evt.newValue) {
      window.location.assign("/");
    }
  });

  /**
   * We wait for hasLoaded here because analytics are loaded in a requestIdleCallback, and so
   * we aren't guaranteed that mixpanel will exist beforehand.
   */
  useEffect(() => {
    if (hasLoaded) {
      track("App Loaded");
    }
  }, [hasLoaded]);

  // if either of these are true, even though the resources have loaded we are forcing the app down
  if (
    coreFeatureFlagsModel.get("APP_UNDERGOING_MAINTENANCE") ||
    coreFeatureFlagsModel.get("ORG_LEVEL_APP_UNDERGOING_MAINTENANCE")
  ) {
    return <CambioDown />;
  }

  return (
    hasLoaded && !requestingToSpoof ?
      <ErrorBoundary>
        {isLoggedIn() ?
          <SpoofBanner />
        : null}
        <Layout>
          {/**
           * TODO: if we can create a page key that is associated with the page component, we
           * can add that key to the error boundary to keep the rest of the app functional if
           * one page goes down
           */}
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
        </Layout>
      </ErrorBoundary>
    : hasErrored ? <CambioDown />
    : null
  );
}

export default dynamic(() => Promise.resolve(App), { ssr: false });

// TODO: temporary. components should load this from the app context file
export { ApplicationContext } from "@/layouts/AppLayout/AppContext";
