/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Constant` - Constant
 * * `Variable` - Variable
 */
export enum PumpFlowTypeEnum {
    CONSTANT = 'Constant',
    VARIABLE = 'Variable',
}
