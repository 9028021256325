import { animated, useTransition } from "@react-spring/web";

import Portal from "@/components/Portal";

import useInertBody from "@/js/hooks/useInertBody";
import useSidePanelContext from "@/js/hooks/useSidePanelContext";

import { type SidePanelState } from "../SidePanelContext";

/**
 * Parent side panel component that is a Portal and is in charge of animating in and out each
 * side panel.
 */
export default function SidePanelManager({ sequence }: SidePanelState) {
  const { containerRef } = useSidePanelContext();
  const transitions = useTransition(sequence.slice(-1), {
    from: { transform: "translateX(100%)" },
    enter: { transform: "translateX(0%)" },
    leave: { transform: "translateX(100%)" },
  });

  useInertBody(!!sequence.length, 400);

  return (
    <Portal timeout={150} visible={!!sequence.length}>
      <div ref={containerRef} className="SidePanelManager">
        {transitions((style, item) => (
          <animated.div style={style}>{item}</animated.div>
        ))}
      </div>
    </Portal>
  );
}
