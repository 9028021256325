/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Natural Gas` - Natural Gas
 * * `Electric` - Electric
 * * `Water Cooled` - Water Cooled
 * * `Air Cooled` - Air Cooled
 * * `Dual Fuel` - Dual Fuel
 * * `Other` - Other
 */
export enum SubCategoryEnum {
    NATURAL_GAS = 'Natural Gas',
    ELECTRIC = 'Electric',
    WATER_COOLED = 'Water Cooled',
    AIR_COOLED = 'Air Cooled',
    DUAL_FUEL = 'Dual Fuel',
    OTHER = 'Other',
}
