/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `UPLOADED` - Uploaded
 * * `INGESTED` - Ingested
 * * `FAILED` - Failed
 */
export enum IngestionStateEnum {
    UPLOADED = 'UPLOADED',
    INGESTED = 'INGESTED',
    FAILED = 'FAILED',
}
