/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Low Pressure` - Low
 * * `Medium Pressure` - Medium
 * * `High Pressure` - High
 */
export enum SteamGeneratorPressureEnum {
    LOW_PRESSURE = 'Low Pressure',
    MEDIUM_PRESSURE = 'Medium Pressure',
    HIGH_PRESSURE = 'High Pressure',
}
