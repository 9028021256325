import { NextRouter } from "next/router";

import { NavItems } from "@/js/constants/nav";

/**
 * We are in an org context if we are view either a subportfolio page (which has an
 * organization_token query parameter) or a portfolio page (which does not have the query
 * parameter so we match against the router path).
 *
 * TODO: rather than path-matching, which is fragile, we should put together some PageKeys -
 * deterministic strings that correspond to the page we are viewing - and check those.
 */
export const isInOrgContext = (router: NextRouter) =>
  !!router.query.organization_token ||
  NavItems.some(({ href }) => new RegExp(href).test(router.asPath));
