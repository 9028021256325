import type { ModalState } from "../ModalContext";

import { animated, useTransition } from "@react-spring/web";
import { useRef } from "react";

import Portal from "@/components/Portal";

import useInertBody from "@/js/hooks/useInertBody";
import useModalContext from "@/js/hooks/useModalContext";

import ModalContext from "../ModalContext";

/**
 * Parent modal sequence component that is a Portal and is in charge of animating in and out each
 * modal in a sequence.
 */
export default function ModalSequenceManager({
  sequence,
  childModal,
  /** this boolean just keeps our child MSM recursion to one-level */
  nested,
}: ModalState & { nested?: boolean }) {
  const modalContext = useModalContext();
  const transitions = useTransition(sequence.slice(-1), {
    from: { scale: 0.9, opacity: 0 },
    enter: { scale: 1, opacity: 1 },
    leave: { scale: 0.9, opacity: 0 },
    config: { tension: 200 },
  });

  useInertBody(!!sequence.length);

  return (
    <Portal visible={!!sequence.length}>
      <div ref={modalContext.msmRef} className="ModalSequenceManager">
        {transitions((style, item) => (
          <animated.div style={style}>{item}</animated.div>
        ))}
      </div>
      {/** recurses in order to show a child modal. 'end' will only close the child, never both */}
      {!nested ?
        <ModalContext.Provider value={{ ...modalContext, msmRef: useRef<HTMLDivElement>() }}>
          <ModalSequenceManager nested sequence={childModal ? [childModal] : []} />
        </ModalContext.Provider>
      : null}
    </Portal>
  );
}
