/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Scheduling` - Scheduling
 * * `Setback` - Setback
 * * `Demand Control` - Demand Control
 * * `Occupancy Based` - Occupancy Based
 * * `Temperature Reset` - Temperature Reset
 */
export enum ControlsFeaturesEnum {
    SCHEDULING = 'Scheduling',
    SETBACK = 'Setback',
    DEMAND_CONTROL = 'Demand Control',
    OCCUPANCY_BASED = 'Occupancy Based',
    TEMPERATURE_RESET = 'Temperature Reset',
}
