/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `BacNet MS/TP` - Bac Net Ms Tp
 * * `BacNet IP` - Bac Net Ip
 * * `LonWorks` - Lon Works
 * * `Modbus` - Modbus
 */
export enum BasControlsProtocolEnum {
    BAC_NET_MS_TP = 'BacNet MS/TP',
    BAC_NET_IP = 'BacNet IP',
    LON_WORKS = 'LonWorks',
    MODBUS = 'Modbus',
}
