/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `APPROVED` - Approved
 * * `PENDING` - Pending
 * * `REJECTED` - Rejected
 */
export enum AdminStatusEnum {
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
}
