/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `UPDATED` - Updated
 * * `CREATED` - Created
 * * `DELETED` - Deleted
 */
export enum ActionEnum {
    UPDATED = 'UPDATED',
    CREATED = 'CREATED',
    DELETED = 'DELETED',
}
