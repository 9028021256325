import { useRouter } from "next/router";
import {
  createContext,
  FunctionComponent,
  MutableRefObject,
  PropsWithChildren,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import { useResources } from "resourcerer";

import Spinner from "@/components/Spinner";

import {
  getCoreFeatureFlags,
  getEffectiveUser,
  getOrganizations,
  getPortfolioOrg,
  getSubportfolios,
} from "@/js/core/coreResources";
import { MetricsDateRange } from "@/js/models/DefaultMetricsDateRangeModel";
import { type FeatureFlags, type Organization, type User } from "@/js/models/types";

import { DisplayCurrencyEnum } from "@/Api/generated";
import { isInOrgContext } from "@/layouts/AppLayout/utils";

// legacy: ApplicationContext. new: AppContext. much shorter! :D
export const ApplicationContext = createContext({} as AppContextValues);
export const AppContext = ApplicationContext;
export const useAppContext = () => useContext(AppContext);

type LastVisited = {
  path: string;
  page: string;
  data?: Record<string, any>;
};

export type AppContextValues = {
  currency: DisplayCurrencyEnum;
  featureConfigurations: FeatureFlags;
  defaultMetricsDateRange: MetricsDateRange;
  lastVisitedPath: MutableRefObject<LastVisited[]>;
  isInPortfolioContext: boolean;
  organizationId: string;
  organizationName: string;
  organizationToken: string;
  organizations: Array<Organization>;
  selectedOrganization: Organization;
  user: User;
  /**
   * This is exclusively for the AppLayout shell, so that we can render the layout before our
   * subportfolio/property features return and then show any locked icons when they show up.
   */
  areSubFeatureFlagsLoading: boolean;
};

/**
 * This is an HOC to wrap the AppLayout, the layout for the logged-in application, with a Context.
 * The Context passes down core resources for now, but that actually isn't necessary. Those can be
 * retrieved with a `getEffectiveUser()` and similar calls inside js/core/coreResources. Other context like
 * org name, id, and currency are all generated from the selectedOrg and can be deprecated. Finally,
 * the HOC holds some state about page states that it passes thru via context.
 *
 * One thing to call out specifically is the treatment of the feature flags resource. We fetch
 * multiple different versions throughout the life of the app because they can depend on both
 * sub-portfolio and a property within the sub-portfolio. We have a notion of two feature flag
 * resources at any given time:
 *
 *   1. The portfolio and system-level feature flags. This is considered a CORE RESOURCE and the
 *      app will not load without them (in the anonymous layout, it's just the system-level).
 *   2. Sub-portfolio and property-level feature flags. This depends on the page we're on. In the
 *      future, each component that needs them will be able to ask for them. Right now, though,
 *      in order to avoid multiple requests, we handle that here and merge with the core resource
 *      before passing down the context.
 */
export const withAppContext = (Component: FunctionComponent<any>) => {
  return (props: PropsWithChildren) => {
    const organizations = getOrganizations().toJSON();
    const router = useRouter();

    const portfolioOrg = getPortfolioOrg();
    /**
     * the "selected org" context of the application is:
     *  1. the subportofolio that matches the org token in the url
     *  2. the portfolio if there is no token in the url
     *  3. the subportfolio if there is no token in the url and the portfolio only has a single subportfolio
     */
    const selectedOrg =
      router.query.organization_token ?
        organizations.find((org) => org.organization__token === router.query.organization_token)
      : getSubportfolios().length === 1 ? getSubportfolios()[0]
      : portfolioOrg;

    const lastVisitedPath = useRef<LastVisited[]>([]);

    const { isLoading, featureFlagsLoadingState, defaultMetricsDateRangeModel, featureFlagsModel } =
      useResources(
        (props) => ({
          featureFlags: {
            params: {
              organization_token: props.organization_token,
              ...(props.space_token ? { property_token: props.space_token } : {}),
            },
            // if this is a portfolio page, we just use core feature flags
            dependsOn: !!props.organization_token,
          },
          defaultMetricsDateRange: {
            params: props.space_token ? { property_token: props.space_token } : {},
            path: { orgId: props.selectedOrgToken },
            // we want to request this whether it's a portfolio or subportfolio token, but only
            // when we're on an org context page
            dependsOn: !!props.selectedOrgToken,
          },
        }),
        {
          space_token: router.query.space_token as string,
          organization_token:
            router.query.organization_token || getSubportfolios().length === 1 ?
              selectedOrg.organization__token
            : undefined,
          ...(isInOrgContext(router) ? { selectedOrgToken: selectedOrg.organization__token } : {}),
        },
      );

    const contextValue: AppContextValues = {
      featureConfigurations: {
        ...getCoreFeatureFlags().toJSON(),
        // combine our subportfolio/property level features with the core resource features
        // (see larger comment above)
        ...featureFlagsModel.toJSON(),
      },
      defaultMetricsDateRange: defaultMetricsDateRangeModel.toJSON(),
      organizations,
      user: getEffectiveUser().toJSON(),

      selectedOrganization: selectedOrg,
      lastVisitedPath,
      areSubFeatureFlagsLoading: featureFlagsLoadingState === "loading",
      // this is only true when we are looking at the portfolio view. when we are "mocking" a
      // portfolio view but under the hood there is just one subportolio and we're using that,
      // this is false
      isInPortfolioContext: selectedOrg === portfolioOrg,
      // highlighting this as its own returned property because it will be used so often
      organizationToken: selectedOrg?.organization__token,
      // these three can be deprecated from context because they can be trivially accessed from
      // the selectedOrg
      organizationName: selectedOrg?.organization__name,
      organizationId: "" + selectedOrg?.organization_id,
      currency: selectedOrg?.display_currency,
    };

    // this is in a layout effect just so that children can edit them in a useEffect and the latest
    // path entry will exist
    useLayoutEffect(() => {
      // used for breadcrumbs
      lastVisitedPath.current.push({
        page: router.asPath.split("?")[0],
        path: router.asPath,
        data: {},
      });
    }, [router.asPath]);

    return (
      <AppContext.Provider value={contextValue}>
        <Component {...props}>
          {isLoading ?
            <Spinner flavor="overlay" />
          : props.children}
        </Component>
      </AppContext.Provider>
    );
  };
};
