/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `2/4's` - Size 2 4
 * * `2/6's` - Size 2 6
 * * `other` - Other
 */
export enum WallWoodFramingSizeEnum {
    _2_4_S = '2/4\'s',
    _2_6_S = '2/6\'s',
    OTHER = 'other',
}
