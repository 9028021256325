import type { NetZeroPledge } from "@/Api/generated";

import { Collection } from "resourcerer";

export default class PledgesCollection extends Collection<NetZeroPledge> {
  url() {
    return "/api/path-to-net-zero/pledges";
  }

  static dependencies = ["organization_token"];
}
