/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `RECOMMENDED` - Recommended
 * * `BUDGETED` - Budgeted
 * * `IN_PROGRESS` - In Progress
 * * `COMPLETE` - Complete
 */
export enum ScheduleStatusEnum {
    RECOMMENDED = 'RECOMMENDED',
    BUDGETED = 'BUDGETED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
}
