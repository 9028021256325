/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `IN_PROGRESS` - In Progress
 * * `COMPLETED` - Completed
 */
export enum CambioSurveyStateEnum {
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
}
