/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `PORTFOLIO` - Portfolio
 * * `SUB_PORTFOLIO` - Sub Portfolio
 */
export enum OrganizationLevelEnum {
    PORTFOLIO = 'PORTFOLIO',
    SUB_PORTFOLIO = 'SUB_PORTFOLIO',
}
