/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `BENCHMARKING` - Benchmarking
 * * `BUILDING_PERFORMANCE_STANDARDS` - Building Performance Standards
 * * `AUDIT_AND_UPGRADE_POLICY` - Audit And Upgrade Policy
 * * `RENEWABLES_SWITCHING` - Renewables Switching
 * * `GREEN_INCENTIVES` - Green Incentives
 */
export enum RegulatoryOrdinanceViewSetTypeEnum {
    BENCHMARKING = 'BENCHMARKING',
    BUILDING_PERFORMANCE_STANDARDS = 'BUILDING_PERFORMANCE_STANDARDS',
    AUDIT_AND_UPGRADE_POLICY = 'AUDIT_AND_UPGRADE_POLICY',
    RENEWABLES_SWITCHING = 'RENEWABLES_SWITCHING',
    GREEN_INCENTIVES = 'GREEN_INCENTIVES',
}
