/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `kwh` - Kwh
 * * `therms` - Therm
 * * `gallons` - Us Gallons
 * * `kbtu` - Kbtu
 * * `metric_tons` - Metric Tons
 */
export enum Unit57dEnum {
    KWH = 'kwh',
    THERMS = 'therms',
    GALLONS = 'gallons',
    KBTU = 'kbtu',
    METRIC_TONS = 'metric_tons',
}
