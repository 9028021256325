export function formatDollarShorthand(number: number) {
  if (number >= 1000000000) {
    const roundedBillions = Math.round(number / 1000000000);

    return `$${roundedBillions}B`;
  } else if (number >= 1000000) {
    const roundedMillions = Math.round(number / 1000000);

    return `$${roundedMillions}M`;
  } else if (number >= 1000) {
    const roundedThousands = Math.round(number / 1000);

    return `$${roundedThousands}K`;
  } else if (number >= 100) {
    const roundedHundreds = Math.round(number / 100) * 100;

    return `$${roundedHundreds}`;
  } else if (number >= 10) {
    const roundedTens = Math.round(number / 10) * 10;

    return `$${roundedTens}`;
  } else {
    return `$${Math.round(number)}`;
  }
}

export function formatPercentile(number: number): string {
  if (!number && number !== 0) {
    return "";
  }

  const lastTwoDigits = number % 100;
  const lastDigit = number % 10;

  if (lastTwoDigits === 11 || lastTwoDigits === 12 || lastTwoDigits === 13) {
    return `${number}th`;
  }

  switch (lastDigit) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
}

export function capitalizeWords(str: string) {
  const words = str.split(" ");
  const capitalizedWords = words.map((word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return capitalizedWords.join(" ");
}

export function indicatePossession(word: string): string {
  if (word.endsWith("s")) {
    return word + "'";
  }

  return word + "'s";
}

export function formatNumber(input: string | number, decimalPlaces: number = 0): string {
  // Parse the input string as a floating-point number
  const number = typeof input === "string" ? parseFloat(input) : input;

  // Check if the input is a valid number
  if (!Number.isFinite(number)) {
    return "Invalid input";
  }

  // Use the toLocaleString method to format the number with commas
  const formattedNumber = number.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  return formattedNumber;
}

/**
 * This can be a handy method for us to use with some of the key words we have in the app. When
 * there is only one of the item, we won't pluralize. Otherwise, by default we'll append an 's',
 * and we'll keep a map of other keywords for more complicated pluralizations without needing some
 * big dictionary.
 */
export function pluralize(unit: string, num: number) {
  // as this grows, this should go in a constants file, but for now...
  const Plurals: Record<string, string> = { property: "properties" };

  return num !== 1 ? Plurals[unit] || `${unit}s` : unit;
}

/**
 * When we want to print things like, "5 subscriptions", "1 property", etc, and have pluralization
 * handled for us.
 */
export function withUnits(num: number, unit: string) {
  return `${num}\xa0${pluralize(unit, num)}`;
}

export function formatPercentage(num: number, precision = 2) {
  return (num / 100).toLocaleString("en-US", {
    maximumFractionDigits: precision,
    style: "percent",
  });
}

export function humanize(str = "", titleize = false) {
  const modified = str.replace(/_/g, " ").toLowerCase();

  return titleize ? capitalizeWords(modified) : modified;
}
