/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `electricity` - Electricity
 */
export enum IndirectEmissionFactorEnergyTypeEnum {
    ELECTRICITY = 'electricity',
}
