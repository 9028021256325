/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `total_fine_amount` - Fines
 * * `eui` - Eui
 * * `carbon_emission` - Carbon Emission Intensity
 * * `act2_roi` - Financial Roi
 * * `act2_carbon_roi` - Carbon Roi
 * * `controls_carbon_roi_model` - Control Carbon Roi
 * * `cooling_carbon_roi_model` - Cooling Carbon Roi
 * * `dhw_carbon_roi_model` - Dhw Carbon Roi
 * * `envelope_carbon_roi_model` - Envelop Carbon Roi
 * * `heating_carbon_roi_model` - Heating Carbon Roi
 * * `lighting_carbon_roi_model` - Lighting Carbon Roi
 * * `controls_cost_model` - Control Cost
 * * `cooling_cost_model` - Cooling Cost
 * * `dhw_cost_model` - Dhw Cost
 * * `envelope_cost_model` - Envelop Cost
 * * `heating_cost_model` - Heating Cost
 * * `lighting_cost_model` - Lighting Cost
 */
export enum MetricTypeEnum {
    TOTAL_FINE_AMOUNT = 'total_fine_amount',
    EUI = 'eui',
    CARBON_EMISSION = 'carbon_emission',
    ACT2_ROI = 'act2_roi',
    ACT2_CARBON_ROI = 'act2_carbon_roi',
    CONTROLS_CARBON_ROI_MODEL = 'controls_carbon_roi_model',
    COOLING_CARBON_ROI_MODEL = 'cooling_carbon_roi_model',
    DHW_CARBON_ROI_MODEL = 'dhw_carbon_roi_model',
    ENVELOPE_CARBON_ROI_MODEL = 'envelope_carbon_roi_model',
    HEATING_CARBON_ROI_MODEL = 'heating_carbon_roi_model',
    LIGHTING_CARBON_ROI_MODEL = 'lighting_carbon_roi_model',
    CONTROLS_COST_MODEL = 'controls_cost_model',
    COOLING_COST_MODEL = 'cooling_cost_model',
    DHW_COST_MODEL = 'dhw_cost_model',
    ENVELOPE_COST_MODEL = 'envelope_cost_model',
    HEATING_COST_MODEL = 'heating_cost_model',
    LIGHTING_COST_MODEL = 'lighting_cost_model',
}
