import { getPortfolioOrg, getSubportfolios } from "@/js/core/coreResources";

/**
 * Helper to use when the current org token might be the implied org token, ie not in the url.
 * This is usually the portfolio token, but can also be the subportfolio token when the customer
 * only has one subportfolio.
 */
export function generateOrgHref(href: string, orgToken: string) {
  const omittedToken =
    getSubportfolios().length === 1 ?
      getSubportfolios()[0].organization__token
    : getPortfolioOrg().organization__token;

  return omittedToken === orgToken ? href : `/${orgToken}${href}`;
}
