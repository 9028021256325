import type {
  ProjectTagType,
  PropertyTagType,
  StatusTagType,
  SystemTagType,
  TagDescription,
  TagType,
} from "./types";

type DescriptionRecord<T extends TagType> = Partial<Record<T, TagDescription>>;

export const StatusTagDescriptions: DescriptionRecord<StatusTagType> = {
  extreme: {
    color: "red",
    strong: true,
    text: "extreme",
  },
  high: {
    color: "orange",
    strong: true,
    text: "high",
  },
  moderate: {
    color: "yellow",
    strong: true,
    text: "moderate",
  },
  low: {
    color: "green",
    strong: true,
    text: "low",
  },
};

export const SystemTagDescriptions: DescriptionRecord<SystemTagType> = {
  lighting: {
    color: "lemon",
    icon: "light-bulb",
    text: "Lighting",
  },
  heating: {
    color: "coral",
    icon: "heat",
    text: "Heating",
  },
  cooling: {
    color: "blue-sky",
    icon: "snowflake",
    text: "Cooling",
  },
  envelope: {
    color: "purple",
    icon: "wall",
    text: "Envelope",
  },
  dhw: {
    color: "pink",
    icon: "drop",
    text: "Domestic Hot Water",
  },
  controls: {
    color: "brown",
    icon: "controls",
    text: "Controls",
  },
};

export const ProjectTagDescriptions: DescriptionRecord<ProjectTagType> = {
  benchmarking: {
    color: "pink",
    icon: "scroll",
    text: "Benchmarking Policy",
  },
  renewables: {
    color: "brown",
    icon: "solar",
    text: "Renewables Switching",
  },
  incentives: {
    color: "lime",
    icon: "leaf",
    text: "Green Incentives",
  },
  "audit-upgrade": {
    color: "blue-sky",
    icon: "clipboard",
    text: "Audit & Upgrade Policy",
  },
  performance: {
    color: "lemon",
    icon: "building",
    text: "Building Performance Standards",
  },
};

export const PropertyTagDescriptions: DescriptionRecord<PropertyTagType> = {
  campus: {
    icon: "campus",
    text: "Campus",
  },
  deal: {
    color: "lemon",
    text: "Deal",
  },
  existing: {
    color: "blue",
    text: "Existing",
  },
  "not-started": {
    text: "Not Started",
  },
  "in-progress": {
    color: "blue",
    text: "In Progress",
  },
  complete: {
    color: "lime",
    text: "Complete",
  },
  declined: {
    color: "coral",
    text: "Declined",
  },
  archived: {
    color: "coral",
    text: "Archived",
  },
};

export const TagDescriptions: DescriptionRecord<TagType> = {
  ...StatusTagDescriptions,
  ...SystemTagDescriptions,
  ...ProjectTagDescriptions,
  ...PropertyTagDescriptions,
};
