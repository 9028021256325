import type { ExecutorFunction } from "resourcerer";

import { useEffect } from "react";
import { useResources } from "resourcerer";

import Modal from "@/components/Modal";
import SearchSelect from "@/components/Select/SearchSelect";
import Spinner from "@/components/Spinner";

import { getRealUser } from "@/js/core/coreResources";
import useDebouncedValue from "@/js/hooks/useDebouncedValue";
import useModalContext from "@/js/hooks/useModalContext";
import useNotificationContext from "@/js/hooks/useNotificationContext";
import useSaveReducer from "@/js/hooks/useSaveReducer";
import SpoofModel from "@/js/models/SpoofModel";

/**
 * This separate component exists solely to keep useModalContext out of AppLayout, so that the
 * whole app does not re-render every time we open a modal. Otherwise we could have just added
 * this listener directly in AppLayout.
 */
export default function (): null {
  const { launch } = useModalContext();

  useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent) => {
      // metaKey gets intercepted by some windows operating systems
      if (getRealUser().get("is_staff") && (evt.metaKey || evt.ctrlKey) && evt.key === "j") {
        launch(<SpoofModal />);
      }
    };

    document.addEventListener("keydown", onKeyDown);

    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  return null;
}

const getResources: ExecutorFunction<"search", { query: string }> = ({ query }) => ({
  search: {
    params: { search_string: query, search_types: ["PORTFOLIO"] },
    dependsOn: !!query,
  },
});

/**
 * For Cambio Admins, looks up users and organizations, and when one is selected, requests to spoof
 * them.
 */
export function SpoofModal() {
  const [debouncedInput, setDebouncedInput] = useDebouncedValue("");
  const { hasLoaded, hasInitiallyLoaded, isLoading, hasErrored, searchModel } = useResources(
    getResources,
    {
      query: debouncedInput,
    },
  );
  const { notify } = useNotificationContext();
  const [{ isSaving }, saveDispatch] = useSaveReducer();

  const results = searchModel.get("results") || [];

  const onSpoof = (token: string | number) => {
    saveDispatch({ type: "saving" });

    new SpoofModel()
      .save({ portfolio_token: token as string })
      .then(() => window.location.reload())
      .catch(() => {
        const { title } = results.find(({ identifier }) => identifier === token);

        saveDispatch({ type: "reset" });
        notify(`Could not start spoof session with ${title}`);
      });
  };

  return (
    <Modal className="SpoofModal" title="Spoof Customer">
      <div className="search-container">
        <SearchSelect
          autofocus
          options={results.map(({ identifier, title, sub_title, type }) => ({
            value: identifier,
            display: title,
            secondaryDisplay: sub_title,
            icon: type === "PORTFOLIO" ? "buildings" : "user",
          }))}
          onChange={setDebouncedInput}
          onSelect={onSpoof}
          placeholder="Search by customer name"
          remote
          hasErrored={hasErrored}
          hasInitiallyLoaded={hasInitiallyLoaded}
          hasLoaded={hasLoaded}
          isLoading={isLoading}
        />
        {isSaving ?
          <Spinner flavor="inline" />
        : null}
      </div>
    </Modal>
  );
}
