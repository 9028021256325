/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Natural Gas` - Natural Gas
 * * `Electricity` - Electricity
 * * `Propane` - Propane
 * * `Fuel Oil` - Fuel Oil
 * * `Gasoline` - Gasoline
 * * `Diesel` - Diesel
 */
export enum FuelSourceEnum {
    NATURAL_GAS = 'Natural Gas',
    ELECTRICITY = 'Electricity',
    PROPANE = 'Propane',
    FUEL_OIL = 'Fuel Oil',
    GASOLINE = 'Gasoline',
    DIESEL = 'Diesel',
}
