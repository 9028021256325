import { Model } from "resourcerer";

export default class MeterBreakdownModel extends Model {
  url() {
    return "/api/properties/meter-consumption";
  }

  static dependencies = [
    "organization_token",
    "space_tokens",
    "start_date",
    "end_date",
    "campus_token",
    "partner_tokens",
    "asset_class_broad_categories",
  ];
}
