import type { ClimateRiskData, PropertyWithClimateRisk } from "@/Api/generated";

import { Collection } from "resourcerer";

export default class ClimateRisksCollection extends Collection<ClimateRiskData> {
  parse(response: { property_with_climate_risk: PropertyWithClimateRisk[] }) {
    return response.property_with_climate_risk[0]?.climate_risks;
  }

  url({ orgToken, propertyToken }: { propertyToken: string; orgToken: string }) {
    return `/api/climate-risks/${orgToken}/${propertyToken}`;
  }

  static dependencies = ["propertyToken", "orgToken"];

  static idAttribute = "category";
}
