/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Point of Use` - Point Of Use
 * * `Multiple Fixtures` - Multiple Fixtures
 */
export enum HeaterInstantaneousSpecificServesEnum {
    POINT_OF_USE = 'Point of Use',
    MULTIPLE_FIXTURES = 'Multiple Fixtures',
}
