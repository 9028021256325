import type {
  BillingDataBasic,
  IngestionRecordSourceEnum,
  MeterDataBasic,
  PropertyMeterDataResponse,
} from "@/Api/generated";

import { Model } from "resourcerer";

// temp adjustment until API fixes serialization
interface ClientMeterDataBasic extends Omit<MeterDataBasic, "source"> {
  source: IngestionRecordSourceEnum;
}

// temp
export interface SpaceMeterAssignmentViewSet {
  name: string;
  area: number;
  fraction_assigned: number;
}

// temp adjustment until API fixes serialization
interface ClientBillingDataBasic extends Omit<BillingDataBasic, "source"> {
  source: IngestionRecordSourceEnum;
}

// temp adjustment until API fixes serialization
interface MeterDetail
  extends Omit<PropertyMeterDataResponse, "units" | "meter_data | billing_data"> {
  units: SpaceMeterAssignmentViewSet[];
  meter_data: Array<ClientMeterDataBasic>;
  billing_data: Array<ClientBillingDataBasic>;
}

export default class MeterDetailModel extends Model<MeterDetail> {
  url({ meterId, spaceToken }: { meterId: number; spaceToken: string }) {
    return `/api/property_space/${spaceToken}/meter/${meterId}/meter-data`;
  }

  static dependencies = ["meterId", "spaceToken"];
}
