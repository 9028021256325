/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `BTU/hr` - Btu Per Hour
 * * `KW` - Kw
 * * `TONS` - Tons
 * * `CFM` - Cfm
 * * `BHP` - Bhp
 */
export enum CapacityUnitsEnum {
    BTU_HR = 'BTU/hr',
    KW = 'KW',
    TONS = 'TONS',
    CFM = 'CFM',
    BHP = 'BHP',
}
