/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `XGBOOST` - Xgboost
 * * `OVERRIDE` - Override
 * * `ACT_I_PROPERTY_RANKING` - Act I Property Ranking
 * * `ACT_II_HEURISTIC_RANKING` - Act Ii Heuristic Ranking
 */
export enum SourceVersionEnum {
    XGBOOST = 'XGBOOST',
    OVERRIDE = 'OVERRIDE',
    ACT_I_PROPERTY_RANKING = 'ACT_I_PROPERTY_RANKING',
    ACT_II_HEURISTIC_RANKING = 'ACT_II_HEURISTIC_RANKING',
}
