/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Temperature` - Temperature
 * * `Enthalpy` - Enthalpy
 * * `Differential Enthalpy` - Differential Enthalpy
 * * `Fixed Point` - Fixed Point
 */
export enum CoolerEconomizerControlEnum {
    TEMPERATURE = 'Temperature',
    ENTHALPY = 'Enthalpy',
    DIFFERENTIAL_ENTHALPY = 'Differential Enthalpy',
    FIXED_POINT = 'Fixed Point',
}
