import type { RenewableEnergyResponse } from "@/Api/generated/models/RenewableEnergyResponse";

import { Model } from "resourcerer";

export default class RenewableEnergyModel extends Model<RenewableEnergyResponse> {
  url() {
    return "/api/path-to-net-zero/renewable-energy";
  }

  static dependencies = ["organization_id"];
}
