/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `NOT_STARTED` - Not Started
 * * `PROCESSING` - Processing
 * * `PROCESSED` - Processed
 */
export enum OnboardingEntryAdminStateEnum {
    NOT_STARTED = 'NOT_STARTED',
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
}
