/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Roof Top Unit` - Rtu
 * * `Air Handling Unit` - Ahu
 * * `Variable Refrigerant Flow` - Vrf
 * * `Boiler` - Boiler
 * * `Chiller` - Chiller
 * * `Air Source Heat Pump` - Ashp
 * * `Ground Source Heat Pump` - Gshp
 * * `Water Source Heat Pump` - Wshp
 * * `Water Loop Heat Pump` - Wlhp
 * * `Window Air Source Heat Pump` - Window Ashp
 * * `Heat Recovery Ventilator` - Hrv
 * * `Energy Recovery Ventilator` - Erv
 * * `Furnace` - Furnace
 * * `Mini Split Heat Pump` - Mini Split Hp
 * * `Make-up Air Unit` - Mau
 * * `Unit Heater` - Unit Heater
 * * `Baseboard Heater` - Baseboard Heater
 * * `Window Air Conditioner` - Window Ac
 * * `Split Air Conditioner` - Split Ac
 * * `Micro Combined Heat and Power` - Micro Chp
 * * `Packaged Terminal Air Conditioner` - Ptac
 * * `Other` - Other
 */
export enum CategoryEnum {
    ROOF_TOP_UNIT = 'Roof Top Unit',
    AIR_HANDLING_UNIT = 'Air Handling Unit',
    VARIABLE_REFRIGERANT_FLOW = 'Variable Refrigerant Flow',
    BOILER = 'Boiler',
    CHILLER = 'Chiller',
    AIR_SOURCE_HEAT_PUMP = 'Air Source Heat Pump',
    GROUND_SOURCE_HEAT_PUMP = 'Ground Source Heat Pump',
    WATER_SOURCE_HEAT_PUMP = 'Water Source Heat Pump',
    WATER_LOOP_HEAT_PUMP = 'Water Loop Heat Pump',
    WINDOW_AIR_SOURCE_HEAT_PUMP = 'Window Air Source Heat Pump',
    HEAT_RECOVERY_VENTILATOR = 'Heat Recovery Ventilator',
    ENERGY_RECOVERY_VENTILATOR = 'Energy Recovery Ventilator',
    FURNACE = 'Furnace',
    MINI_SPLIT_HEAT_PUMP = 'Mini Split Heat Pump',
    MAKE_UP_AIR_UNIT = 'Make-up Air Unit',
    UNIT_HEATER = 'Unit Heater',
    BASEBOARD_HEATER = 'Baseboard Heater',
    WINDOW_AIR_CONDITIONER = 'Window Air Conditioner',
    SPLIT_AIR_CONDITIONER = 'Split Air Conditioner',
    MICRO_COMBINED_HEAT_AND_POWER = 'Micro Combined Heat and Power',
    PACKAGED_TERMINAL_AIR_CONDITIONER = 'Packaged Terminal Air Conditioner',
    OTHER = 'Other',
}
