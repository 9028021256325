/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Cooling Tower` - Cooling Tower
 * * `Fluid Cooler` - Fluid Cooler
 * * `None` - None
 */
export enum CondenserLoopSupplementalCoolingTypeEnum {
    COOLING_TOWER = 'Cooling Tower',
    FLUID_COOLER = 'Fluid Cooler',
    NONE = 'None',
}
