/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `DATA_GAP` - Data Gap
 * * `EUI_OUTLIER` - Eui Outlier
 * * `MISSING_METER` - Missing Meter
 * * `SMA_EXCEEDED_100` - Sma Exceeded 100
 * * `SMA_CONSUMPTION_DATA_FLOW_THROUGH` - Sma Consumption Data Flow Through
 * * `ANNUAL_EUI_VARIANCE` - Annual Eui Variance
 * * `ANNUAL_CARBON_VARIANCE` - Annual Carbon Variance
 * * `MONTHLY_METER_DATA_VARIANCE` - Monthly Meter Data Variance
 * * `ANNUAL_EUI_BOUNDS` - Annual Eui Bounds
 * * `ANNUAL_EUI_BENCHMARK_VARIANCE` - Annual Eui Benchmark Variance
 */
export enum ErrorTypeEnum {
    DATA_GAP = 'DATA_GAP',
    EUI_OUTLIER = 'EUI_OUTLIER',
    MISSING_METER = 'MISSING_METER',
    SMA_EXCEEDED_100 = 'SMA_EXCEEDED_100',
    SMA_CONSUMPTION_DATA_FLOW_THROUGH = 'SMA_CONSUMPTION_DATA_FLOW_THROUGH',
    ANNUAL_EUI_VARIANCE = 'ANNUAL_EUI_VARIANCE',
    ANNUAL_CARBON_VARIANCE = 'ANNUAL_CARBON_VARIANCE',
    MONTHLY_METER_DATA_VARIANCE = 'MONTHLY_METER_DATA_VARIANCE',
    ANNUAL_EUI_BOUNDS = 'ANNUAL_EUI_BOUNDS',
    ANNUAL_EUI_BENCHMARK_VARIANCE = 'ANNUAL_EUI_BENCHMARK_VARIANCE',
}
