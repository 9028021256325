import { useEffect } from "react";

/**
 * Simple hook for responding to resize events with a ResizeObserver.
 */
export default function useResizeObserver(
  node: HTMLElement,
  callback: (entries: ResizeObserverEntry[]) => void,
) {
  useEffect(() => {
    if (node) {
      const resizeObserver = new ResizeObserver(callback);

      resizeObserver.observe(node);

      return () => {
        resizeObserver.unobserve(node);
      };
    }
  }, [node, callback]);
}
