/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `DRAFTED` - Drafted
 * * `REVIEWED` - Reviewed
 * * `PUBLISHED` - Published
 */
export enum RetrofitProjectStateEnum {
    DRAFTED = 'DRAFTED',
    REVIEWED = 'REVIEWED',
    PUBLISHED = 'PUBLISHED',
}
