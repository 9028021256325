import type { RetrofitProject } from "@/Api/generated";

import { Collection } from "resourcerer";

export default class RetrofitProjectsCollection extends Collection<RetrofitProject> {
  url() {
    return "/api/retrofit-project";
  }

  static idAttribute = "token";

  static dependencies = ["property__token"];
}
