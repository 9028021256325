/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `RECOMMENDED` - Recommended
 * * `TODO` - Todo
 * * `IN_PROGRESS` - In Progress
 * * `COMPLETE` - Complete
 */
export enum PropertyRegulatoryOrdinanceStatusEnum {
    RECOMMENDED = 'RECOMMENDED',
    TODO = 'TODO',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
}
