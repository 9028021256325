/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `LP_INVESTOR` - LP_INVESTOR
 * * `JV_PARTNER` - JV_PARTNER
 * * `LENDER` - LENDER
 * * `CLIENT` - CLIENT
 * * `GP` - GP
 * * `OTHER` - OTHER
 */
export enum PartnerTypeEnum {
    LP_INVESTOR = 'LP_INVESTOR',
    JV_PARTNER = 'JV_PARTNER',
    LENDER = 'LENDER',
    CLIENT = 'CLIENT',
    GP = 'GP',
    OTHER = 'OTHER',
}
