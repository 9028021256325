/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Outside Air` - Outside Air
 * * `Mechanical Room` - Mechanical Room
 */
export enum HeaterElectricSpecificHeatPumpAirSourceEnum {
    OUTSIDE_AIR = 'Outside Air',
    MECHANICAL_ROOM = 'Mechanical Room',
}
