/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `WHOLE_BUILDING_DATA` - Whole Building Data
 * * `TENANT_SPECIFIC_CONSUMPTION` - Tenant Specific Consumption
 * * `OTHER` - Other
 */
export enum BuildingCoverageEnum {
    WHOLE_BUILDING_DATA = 'WHOLE_BUILDING_DATA',
    TENANT_SPECIFIC_CONSUMPTION = 'TENANT_SPECIFIC_CONSUMPTION',
    OTHER = 'OTHER',
}
