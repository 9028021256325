import { Collection } from "resourcerer";

export type CertificationTypes = "EnergyStar" | "LEED";

interface AggregateCertification {
  certification_type: CertificationTypes;
  total_floor_area: number;
  certification_levels: {
    level: string;
    floor_area: number;
  }[];
}

export default class CertificationsCollection extends Collection<AggregateCertification> {
  parse(response: { certification_aggregations: AggregateCertification[] }) {
    return response.certification_aggregations;
  }

  url() {
    return "/api/certifications";
  }

  static dependencies = ["organization_token", "partner_tokens", "asset_class_broad_categories"];

  static idAttribute = "certification_type";
}
