/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `SUB_PORTFOLIO_OVERVIEW` - Sub Portfolio Overview
 */
export enum ReportTypeEnum {
    SUB_PORTFOLIO_OVERVIEW = 'SUB_PORTFOLIO_OVERVIEW',
}
