/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Private Landlord` - Private Landlord
 * * `Publicly-traded landlord` - Publicly Traded Landlord
 * * `Pre-Seed startup` - Pre Seed Startup
 * * `Seed startup` - Seed Startup
 * * `Series A startup` - Series A Startup
 * * `Series B startup` - Series B Startup
 * * `Series C+ startup` - Series C Plus Startup
 * * `Publicly-traded corporate` - Publicly Traded Corporate
 */
export enum OrganizationTypeDetailsEnum {
    PRIVATE_LANDLORD = 'Private Landlord',
    PUBLICLY_TRADED_LANDLORD = 'Publicly-traded landlord',
    PRE_SEED_STARTUP = 'Pre-Seed startup',
    SEED_STARTUP = 'Seed startup',
    SERIES_A_STARTUP = 'Series A startup',
    SERIES_B_STARTUP = 'Series B startup',
    SERIES_C_STARTUP = 'Series C+ startup',
    PUBLICLY_TRADED_CORPORATE = 'Publicly-traded corporate',
}
