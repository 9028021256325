/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `PROPERTY` - Property
 * * `UNIT` - Unit
 */
export enum SpaceTypeEnum {
    PROPERTY = 'PROPERTY',
    UNIT = 'UNIT',
}
