import type { ExecutorFunction } from "resourcerer";

import sortBy from "lodash/sortBy";
import { useRouter } from "next/router";
import { useResources } from "resourcerer";

import HovercardToggle from "@/components/HovercardToggle";
import SelectableList from "@/components/SelectableList";
import SvgIcon from "@/components/SvgIcon";

import { NavItems } from "@/js/constants/nav";
import { getPortfolioOrg, getSubportfolios } from "@/js/core/coreResources";
import { hasFullPortfolioAccess } from "@/js/utils/permissions";
import { withUnits } from "@/js/utils/stringFormatter";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

// this will get the properties for the selected org so that we can display
// the total number in the button
const getResources: ExecutorFunction<"properties", { orgToken: string }> = (props) => ({
  properties: { params: { organization_token: props.orgToken } },
});

/**
 *
 */
export default function PortfolioPicker({ selectedOrgToken }: { selectedOrgToken: string }) {
  const { organizations } = useAppContext();
  // note that this is not the same as `selectedOrganization` from useAppContext. selectedOrgToken
  // should always exist, even on pages outside of an org context.
  const selectedOrg = organizations.find(
    ({ organization__token }) => organization__token === selectedOrgToken,
  );
  const { hasLoaded, propertiesCollection } = useResources(getResources, {
    orgToken: selectedOrgToken,
  });
  const hasOneSubPortfolio = getSubportfolios().length <= 1;
  const orgNameDisplay =
    hasOneSubPortfolio || selectedOrgToken === getPortfolioOrg().organization__token ?
      "All Properties"
    : selectedOrg?.organization__name;

  return (
    <div className="PortfolioPicker">
      <HovercardToggle
        hovercardClassName="subportfolio-hovercard"
        contents={() => <SubportfolioDropdown selectedOrgToken={selectedOrgToken} />}
        disabled={hasOneSubPortfolio}
      >
        <button>
          <span>
            <p>{orgNameDisplay}</p>
            <small>{hasLoaded ? withUnits(propertiesCollection.length, "property") : "\xa0"}</small>
          </span>
          {!hasOneSubPortfolio ?
            <SvgIcon name="chevron-down" />
          : null}
        </button>
      </HovercardToggle>
    </div>
  );
}

/**
 * This is in its own component to fetch the whole portfolio property resource. We don't want this
 * to fetch until the hovercard is opened. TODO: property counts may get included in the
 * /organizations endpoint, in which case we won't need this separated out.
 */
function SubportfolioDropdown({ selectedOrgToken }: { selectedOrgToken: string }) {
  // this is to show the total number properties across the portfolio
  const { hasLoaded, propertiesCollection } = useResources(getResources, {
    orgToken: getPortfolioOrg().organization__token,
  });
  const router = useRouter();
  const { featureConfigurations, organizations } = useAppContext();

  return (
    <SelectableList
      groups={[
        {
          key: "total",
          items: [
            {
              key: "all",
              display: "All Properties",
              secondaryDisplay:
                hasLoaded ? withUnits(propertiesCollection.length, "property") : "\xa0",
              href: getSubportfolioLink(null, selectedOrgToken, router.asPath),
              selected: selectedOrgToken === getPortfolioOrg().organization__token,
            },
          ],
        },
        {
          key: "subportfolios",
          display: "Sub Portfolios",
          icon: "bank",
          items: sortBy(
            organizations.filter(
              ({ organization_id }) => organization_id !== getPortfolioOrg().organization_id,
            ),
            "organization__name",
          ).map(({ organization__name, organization__token }) => ({
            key: organization__token,
            display: organization__name,
            href: getSubportfolioLink(organization__token, selectedOrgToken, router.asPath),
            selected: selectedOrgToken === organization__token,
          })),
        },
        ...((
          !featureConfigurations.TEMP_ORG_LEVEL_NEW_USER_PERMISSIONS_ENABLED ||
          hasFullPortfolioAccess()
        ) ?
          [
            {
              key: "manage",
              items: [
                {
                  key: "manage-sub-portfolios",
                  display: "Manage Sub Portfolios",
                  href: "/portfolio",
                },
              ],
            },
          ]
        : []),
      ]}
    />
  );
}

/**
 * What url should we navigate to when an item in the PortfolioPicker is clicked? That's what this
 * function determines, because it depends on what org context we're currently in. For example, if
 * we're on an org context page, we should just swap out the subportfolio ids and stay on that page.
 * If we're out of an org context, we should just go that subportfolio's dashboard, etc.
 *
 * orgToken: the token of a given subportfolio that we want a link for
 * currentOrgToken: is the token of the current org context (ie from the url, but in reality from
 *   the selectedOrgToken prop right now).
 */
export function getSubportfolioLink(orgToken: string, currentOrgToken: string, path: string) {
  // make sure that we're on a valid org context page. ie if we're on partners, these links
  // should just go to the dashboard
  const currentPage = NavItems.find(({ href }) => new RegExp(href).test(path))?.href;
  // we'll find the current page after the org token in /{orgToken}/{page}/{otherPathParts}/...
  // unfortunately this won't take tabs into account...
  const nextPath = currentPage || "/dashboard";

  if (currentOrgToken === getPortfolioOrg().organization__token) {
    // we're in a portfolio context, want links for a subportfolio context
    return `/${orgToken}${nextPath}`;
  } else if (!orgToken) {
    // we want links for the portfolio context
    return nextPath;
  }

  // going from subportfolio to another subportfolio
  return `/${orgToken}${nextPath}`;
}
