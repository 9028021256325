/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `VFD` - Vfd
 * * `Digital Scroll` - Digital Scroll
 * * `Staged` - Staged
 * * `Inverter` - Inverter
 * * `Variable Vanes` - Variable Vanes
 * * `Hot Gas Bypass` - Hot Gas Bypass
 */
export enum CoolerCapacityControlEnum {
    VFD = 'VFD',
    DIGITAL_SCROLL = 'Digital Scroll',
    STAGED = 'Staged',
    INVERTER = 'Inverter',
    VARIABLE_VANES = 'Variable Vanes',
    HOT_GAS_BYPASS = 'Hot Gas Bypass',
}
