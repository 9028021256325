/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Roof` - Roof
 * * `Mechanical Room` - Mechanical Room
 * * `Exterior` - Exterior
 * * `Other` - Other
 */
export enum LocationEnum {
    ROOF = 'Roof',
    MECHANICAL_ROOM = 'Mechanical Room',
    EXTERIOR = 'Exterior',
    OTHER = 'Other',
}
