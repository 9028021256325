/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `NONE` - None
 * * `BRONZE` - Bronze
 * * `SILVER` - Silver
 * * `GOLD` - Gold
 */
export enum ServiceTierEnum {
    NONE = 'NONE',
    BRONZE = 'BRONZE',
    SILVER = 'SILVER',
    GOLD = 'GOLD',
}
