/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `USD` - Usd
 * * `CAD` - Cad
 * * `EUR` - Eur
 * * `GBP` - Gbp
 * * `AUD` - Aud
 * * `JPY` - Jpy
 * * `CNY` - Cny
 */
export enum DisplayCurrencyEnum {
    USD = 'USD',
    CAD = 'CAD',
    EUR = 'EUR',
    GBP = 'GBP',
    AUD = 'AUD',
    JPY = 'JPY',
    CNY = 'CNY',
}
