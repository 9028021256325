/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Central` - Central
 * * `Zone` - Zone
 * * `Terminal` - Terminal
 * * `Other` - Other
 */
export enum ServiceTypeEnum {
    CENTRAL = 'Central',
    ZONE = 'Zone',
    TERMINAL = 'Terminal',
    OTHER = 'Other',
}
