import type { RetrofitROIForecast } from "@/Api/generated";

import { Model } from "resourcerer";

export default class RoiForecastModel extends Model<RetrofitROIForecast> {
  url({ propertyToken }: { propertyToken: string }) {
    return `/api/retrofit-roi-forecast/${propertyToken}`;
  }

  static dependencies = ["propertyToken"];
}
