/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Water Source Heat Pump` - Water Source Heat Pump
 * * `Geothermal` - Geothermal
 * * `Other` - Other
 */
export enum CondenserLoopTypeEnum {
    WATER_SOURCE_HEAT_PUMP = 'Water Source Heat Pump',
    GEOTHERMAL = 'Geothermal',
    OTHER = 'Other',
}
