/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Electric Resistance` - Electric Resistance
 * * `Fuel Fired` - Fuel Fired
 * * `None` - None
 */
export enum HeatPumpBackupTypeEnum {
    ELECTRIC_RESISTANCE = 'Electric Resistance',
    FUEL_FIRED = 'Fuel Fired',
    NONE = 'None',
}
