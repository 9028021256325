/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Steel and Glass Curtain Wall` - Steel Glass Curtain Wall
 * * `Mass construction (stone, CMU, brick, precast concrete)` - Mass Construction
 * * `Metal Framed Light Gauge Steel` - Metal Framed
 * * `Wood Framed` - Wood Framed
 * * `Other` - Other
 */
export enum WallConstructionTypeEnum {
    STEEL_AND_GLASS_CURTAIN_WALL = 'Steel and Glass Curtain Wall',
    MASS_CONSTRUCTION_STONE_CMU_BRICK_PRECAST_CONCRETE_ = 'Mass construction (stone, CMU, brick, precast concrete)',
    METAL_FRAMED_LIGHT_GAUGE_STEEL = 'Metal Framed Light Gauge Steel',
    WOOD_FRAMED = 'Wood Framed',
    OTHER = 'Other',
}
