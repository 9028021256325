/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `GENERAL` - General
 * * `COOLING` - Cooling
 * * `HEATING` - Heating
 * * `LIGHTING` - Lighting
 * * `ENVELOPE` - Envelope
 * * `CONTROL` - Control
 * * `DOMESTIC_HOT_WATER` - Domestic Hot Water
 */
export enum RankingResultSystemTypeEnum {
    GENERAL = 'GENERAL',
    COOLING = 'COOLING',
    HEATING = 'HEATING',
    LIGHTING = 'LIGHTING',
    ENVELOPE = 'ENVELOPE',
    CONTROL = 'CONTROL',
    DOMESTIC_HOT_WATER = 'DOMESTIC_HOT_WATER',
}
