import type { PropertyOnboardingInfo } from "@/Api/generated";

import { Collection } from "resourcerer";

import { getEffectiveUser } from "@/js/core/coreResources";

export default class AssignedPropertiesCollection extends Collection<PropertyOnboardingInfo> {
  parse(response: { assigned_properties: PropertyOnboardingInfo[] }) {
    return response.assigned_properties;
  }

  url() {
    return `/api/users/${getEffectiveUser().get("token")}/assigned-properties`;
  }

  static idAttribute = "token";
}
