import type { RankingCategory } from "@/js/models/PropertiesCollection";

import { Address } from "@/Api/generated";

/**
 * Shows general area of a property address with whatever pieces of the address we have.
 */
export function getAddressShorthand({
  city,
  state,
  full_country_name,
}: Partial<Pick<Address, "city" | "state" | "full_country_name">>) {
  return [city, state, full_country_name].filter(Boolean).join(", ");
}

/**
 * Returns true if a ranking category exists and is enabled.
 */
export function hasRankingCategory(
  rankings: RankingCategory[] = [],
  category: RankingCategory["category"],
) {
  return !!rankings.find((propertyRankingCategory) => propertyRankingCategory.category === category)
    ?.category_enabled;
}
