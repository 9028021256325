/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Heater` - Heater
 * * `Cooler` - Cooler
 * * `Fan` - Fan
 * * `Pump` - Pump
 * * `Heat Pump` - Heat Pump
 * * `Steam Generator` - Steam Generator
 * * `Cooling Tower` - Cooling Tower
 */
export enum ComponentClassEnum {
    HEATER = 'Heater',
    COOLER = 'Cooler',
    FAN = 'Fan',
    PUMP = 'Pump',
    HEAT_PUMP = 'Heat Pump',
    STEAM_GENERATOR = 'Steam Generator',
    COOLING_TOWER = 'Cooling Tower',
}
