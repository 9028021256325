import { Collection } from "resourcerer";

import { type Organization } from "@/js/models/types";

export default class OrganizationsCollection extends Collection<Organization> {
  parse({ organizations }: { organizations: Organization[] }) {
    return organizations;
  }

  static measure = true;

  url() {
    return "/api/account/organizations";
  }
}
