/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Gravity` - Gravity
 * * `Pumped` - Pumped
 * * `Vacuum` - Vacuum
 */
export enum SteamGeneratorCondensateRecoveryEnum {
    GRAVITY = 'Gravity',
    PUMPED = 'Pumped',
    VACUUM = 'Vacuum',
}
