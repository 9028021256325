/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `millions/USD` - Millions Usd
 */
export enum SizeUnitsEnum {
    MILLIONS_USD = 'millions/USD',
}
