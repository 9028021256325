/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `ENERGY` - Energy
 * * `WATER` - Water
 * * `WASTE` - Waste
 */
export enum MeterTypeReferenceTypeEnum {
    ENERGY = 'ENERGY',
    WATER = 'WATER',
    WASTE = 'WASTE',
}
