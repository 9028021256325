/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Manual` - Manual
 * * `Programmable` - Programmable
 * * `Building Management System` - Bms
 * * `Other` - Other
 */
export enum ControlsTypeEnum {
    MANUAL = 'Manual',
    PROGRAMMABLE = 'Programmable',
    BUILDING_MANAGEMENT_SYSTEM = 'Building Management System',
    OTHER = 'Other',
}
