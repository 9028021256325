import { Collection, Model } from "resourcerer";

import { getPortfolioOrg } from "@/js/core/coreResources";

import { type PartnerProfile } from "@/Api/generated/models/PartnerProfile";

export class PartnerModel extends Model<PartnerProfile> {
  static idAttribute = "token";
  static defaults: Partial<PartnerProfile> = { report_subscriptions: [] };

  parse(response: PartnerProfile | { partner_profile: PartnerProfile }) {
    return "partner_profile" in response ? response.partner_profile : response;
  }
}

export default class PartnersCollection extends Collection<PartnerProfile> {
  Model = PartnerModel;

  fetch(options = {}) {
    return Collection.prototype.fetch.call(this, {
      ...options,
      params: { portfolio_token: getPortfolioOrg().organization__token },
    }) as Promise<[this, Response]>;
  }

  url() {
    return "/api/partners";
  }

  static idAttribute = "token";
}
