/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `ASSET_MANAGER` - Asset Manager
 * * `PROPERTY_MANAGER` - Property Manager
 * * `SUSTAINABILITY` - Sustainability
 * * `INVESTMENTS` - Investments
 * * `ACCOUNTS_PAYABLE_OR_FINANCE` - Accounts Payable Or Finance
 * * `OTHER` - Other
 */
export enum CompanyRolesEnum {
    ASSET_MANAGER = 'ASSET_MANAGER',
    PROPERTY_MANAGER = 'PROPERTY_MANAGER',
    SUSTAINABILITY = 'SUSTAINABILITY',
    INVESTMENTS = 'INVESTMENTS',
    ACCOUNTS_PAYABLE_OR_FINANCE = 'ACCOUNTS_PAYABLE_OR_FINANCE',
    OTHER = 'OTHER',
}
