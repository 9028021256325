/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `YES` - Yes
 * * `NO` - No
 * * `NOT_SURE` - Not Sure
 */
export enum HasRenewableEnergyCertificateEnum {
    YES = 'YES',
    NO = 'NO',
    NOT_SURE = 'NOT_SURE',
}
