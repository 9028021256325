/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `USER` - USER
 * * `PORTFOLIO` - PORTFOLIO
 * * `SUB_PORTFOLIO` - SUB_PORTFOLIO
 * * `PROPERTY` - PROPERTY
 */
export enum SearchTypesEnum {
    USER = 'USER',
    PORTFOLIO = 'PORTFOLIO',
    SUB_PORTFOLIO = 'SUB_PORTFOLIO',
    PROPERTY = 'PROPERTY',
}
