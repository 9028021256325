import { Model } from "resourcerer";

import { CambioSurveyConfiguration } from "@/Api/generated";

export default class SurveyConfigurationModel extends Model<CambioSurveyConfiguration> {
  url({ formId }: { formId: string }) {
    return `/api/cambio_survey/${formId}/survey_content`;
  }

  static dependencies: ["formId"];
}
