/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Constant Volume` - Constant Volume
 * * `Variable Volume` - Variable Volume
 */
export enum FanTypeEnum {
    CONSTANT_VOLUME = 'Constant Volume',
    VARIABLE_VOLUME = 'Variable Volume',
}
