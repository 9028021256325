/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Hot Gas Reheat` - Hot Gas Reheat
 * * `Steam` - Steam
 * * `Evaporative` - Evaporative
 * * `Water Mist Injection` - Water Mist Injection
 */
export enum AirTreatmentMethodEnum {
    HOT_GAS_REHEAT = 'Hot Gas Reheat',
    STEAM = 'Steam',
    EVAPORATIVE = 'Evaporative',
    WATER_MIST_INJECTION = 'Water Mist Injection',
}
