import type { InputProps } from "@/components/Input";

import { forwardRef, MutableRefObject, useRef } from "react";

import Input from "@/components/Input";
import SvgIcon from "@/components/SvgIcon";

const SearchInput = forwardRef<HTMLInputElement, InputProps>((props, _ref) => {
  const defaultRef = useRef<HTMLInputElement>();
  const ref = _ref || defaultRef;

  return (
    <div
      className="SearchInput"
      // this is for capturing the click on the search icon
      onClick={() => (ref as MutableRefObject<HTMLInputElement>)?.current?.focus()}
    >
      <SvgIcon name="search" />
      <Input ref={ref} {...props} />
    </div>
  );
});

export default SearchInput;
