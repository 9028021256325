/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `square feet` - Square Feet
 */
export enum AreaUnitEnum {
    SQUARE_FEET = 'square feet',
}
