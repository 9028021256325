import { createContext, MutableRefObject, useEffect, useMemo, useReducer, useRef } from "react";

import SidePanelManager from "../SidePanelManager";

export type SidePanelState = { sequence: Array<JSX.Element> };
type SidePanelAction =
  | { type: "start"; payload: JSX.Element }
  | { type: "next"; payload?: JSX.Element }
  | { type: "prev" }
  | { type: "reset" };

export type SidePanelContext = {
  /** Opens a panel if there isn't already one open */
  openPanel: (panel: JSX.Element, cb?: () => void) => void;
  /** Closes current panel */
  closePanel: () => void;
  /** Moves forward/back along a panel sequence */
  nextPanel: (panel?: JSX.Element, cb?: () => void) => void;
  prevPanel: () => void;
  containerRef: MutableRefObject<HTMLDivElement>;

  /** This setter/getter duo is for keeping track of data between panels, which could be useful? */
  setSequenceData: (obj: Record<string, any>) => void;
  getSequenceData: (key: string) => any;
};

const SidePanelContext = createContext<SidePanelContext>({} as SidePanelContext);

/**
 * Actions here mirror what we have with the modal sequence, but panel sequences will be much rarer.
 * Most often we will just use open/close callbacks.
 */
export const sidePanelReducer = (state: SidePanelState, action: SidePanelAction) => {
  switch (action.type) {
    case "start":
      return { sequence: [action.payload] };
    case "next":
      return { sequence: action.payload ? state.sequence.concat(action.payload) : [] };
    case "prev":
      return { sequence: state.sequence.slice(0, -1) };
    case "reset":
      return { sequence: [] };
  }
};

export const withSidePanelContextProvider =
  (Component: (props: any) => JSX.Element) => (props: any) => {
    const [state, dispatch] = useReducer(sidePanelReducer, { sequence: [] });
    const panelData = useRef<Record<string, any>>({});
    const callbackRef = useRef<() => void>();
    const containerRef = useRef<HTMLDivElement>();

    const value: SidePanelContext = useMemo(
      () => ({
        openPanel: (panel, cb) => {
          callbackRef.current = cb;

          if (!state.sequence.length) {
            dispatch({ type: "start", payload: panel });
          }
        },
        closePanel: () => dispatch({ type: "reset" }),

        nextPanel: (panel) => dispatch({ type: "next", payload: panel }),
        prevPanel: () => dispatch({ type: "prev" }),

        containerRef,
        setSequenceData: (obj) => Object.assign(panelData.current, obj),
        getSequenceData: (key) => panelData.current[key],
      }),
      [state],
    );

    useEffect(() => {
      if (!state.sequence.length) {
        // fire the callback and reset refs
        callbackRef.current?.();
        panelData.current = {};
      }
    }, [state.sequence.length]);

    return (
      <SidePanelContext.Provider value={value}>
        <SidePanelManager {...state} />
        <Component {...props} />
      </SidePanelContext.Provider>
    );
  };

export default SidePanelContext;
