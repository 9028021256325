export type Environments = "production" | "demo" | "qa" | "dev";

const DOMAINS: Record<Environments, string[]> = {
  production: ["app.cambio.ai"],
  demo: ["demo.cambio.ai"],
  qa: ["qa.cambio.ai"],
  dev: ["localhost", "127.0.0.1"],
};

/**
 * Uses the global location object to determine what environment we're in. We default to production
 * because we can assume that will be the most stringent of our environments, ie CSP headers might
 * allow for inline scripting locally, but wouldn't in production.
 */
const getCurrentEnv = (): Environments => {
  const environment = Object.keys(DOMAINS).find((env) =>
    DOMAINS[env as Environments].some((host: string) => window.location.hostname === host),
  ) as Environments;

  return environment || "production";
};

export const currentEnv = typeof window !== "undefined" ? getCurrentEnv() : undefined;

export const isProduction = () => currentEnv === "production";
