/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Feedwater Preheat` - Feedwater Preheat
 * * `DHW Preheat` - Dhw Preheat
 * * `Process Heat` - Process Heat
 * * `Other` - Other
 */
export enum SteamGeneratorFlueGasUseEnum {
    FEEDWATER_PREHEAT = 'Feedwater Preheat',
    DHW_PREHEAT = 'DHW Preheat',
    PROCESS_HEAT = 'Process Heat',
    OTHER = 'Other',
}
