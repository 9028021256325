/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `PROPERTY` - Property
 * * `SUB_PORTFOLIO` - Sub Portfolio
 * * `METER` - Meter
 */
export enum ErrorScopeEnum {
    PROPERTY = 'PROPERTY',
    SUB_PORTFOLIO = 'SUB_PORTFOLIO',
    METER = 'METER',
}
