/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Boiler` - Boiler
 * * `Electric` - Electric
 * * `None` - None
 */
export enum CondenserLoopSupplementalHeatingTypeEnum {
    BOILER = 'Boiler',
    ELECTRIC = 'Electric',
    NONE = 'None',
}
