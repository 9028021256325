import { useReducer } from "react";

type SaveState = {
  isSaving: boolean;
  wasSuccessful: boolean;
  hasErrored: boolean;
};

export type SaveActionTypes = "saving" | "error" | "success" | "reset";

export const initialState = {
  isSaving: false,
  wasSuccessful: false,
  hasErrored: false,
};

export const saveReducer = (state: SaveState, action: { type: SaveActionTypes }) => {
  switch (action.type) {
    case "error":
      return {
        isSaving: false,
        wasSuccessful: false,
        hasErrored: true,
      };
    case "saving":
      return {
        isSaving: true,
        wasSuccessful: false,
        hasErrored: false,
      };
    case "success":
      return {
        isSaving: false,
        wasSuccessful: true,
        hasErrored: false,
      };
    case "reset":
    default:
      return initialState;
  }
};

/**
 * Generic hook for tracking save request loading states (_not_ for fetching). Allows for a single
 * place to change between saving, success, and error states.
 */
export default function useSaveReducer(_initialState: Partial<SaveState> = {}) {
  return useReducer(saveReducer, { ...initialState, ..._initialState });
}
