import { ParsedUrlQuery } from "querystring";

import domtoimage from "dom-to-image";
import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { createPortal, flushSync } from "react-dom";

// The RenderContentOptions type is used to define the options for the renderContentForScreenshots function
// The function is used to render JSX content in a hidden container
// Screenshots can then be taken of the content using the takeScreenshots function
type RenderContentOptions = {
  // The JSX element to render in the hidden container
  jsx?: JSX.Element;
  // The query object is used to override the query params for the screenshot job
  query?: ParsedUrlQuery;
  // Whether the property image is valid or not
  isImageValid?: boolean;
  // The width of the container element
  width?: string;
  // The height of the container element
  height?: string;
  // The screenshot ID is used to uniquely identify the screenshot job
  screenshotId: string;
};

// The ScreenshotContextType is used to define the context object for the screenshot context
// The context object contains the renderContentForScreenshots and takeScreenshots functions
export type ScreenshotContextType = {
  renderContentForScreenshots: (options: RenderContentOptions) => void;
  takeScreenshots: (
    screenShotId: string,
    querySelectors: string[],
  ) => Promise<{ [key: string]: string }>;
};
// The ScreenshotJobContextType is used to define the context object for a specific screenshot job
// The context object contains the isAnimationActive flag and the query object
export type ScreenshotJobContextType = {
  isAnimationActive: boolean;
  isImageValid: boolean;
  query: ParsedUrlQuery;
};
// The screenshot context is used to make available the renderContentForScreenshots and takeScreenshots functions
// These functions are used to render content in a hidden container and take screenshots of the content
const ScreenshotContext = createContext<ScreenshotContextType | undefined>(undefined);
// The screenshot job context is used to pass in query param overrides and disable animations for specific screenshot jobs
// Having separate contexts allows us to override the query params with different values for different screenshot jobs
const ScreenshotJobContext = createContext<ScreenshotJobContextType | undefined>(undefined);

// This hook is used to get the screenshot context
// If the context is not available, it returns an empty object
const useScreenshotContext = (): ScreenshotContextType => {
  const context = useContext(ScreenshotContext);

  if (!context) {
    return {} as ScreenshotContextType;
  }

  return context;
};

// This hook is used to get the context for a specific screenshot job
// If the context is not available, it returns a default context with isAnimationActive set to true and an empty query object
const useScreenshotJobContext = () => {
  const context = useContext(ScreenshotJobContext);

  if (!context) {
    return { isAnimationActive: true, query: {}, isImageValid: null } as ScreenshotJobContextType;
  }

  return context;
};

const ScreenshotContextProvider = ({ children }: PropsWithChildren) => {
  const [jsxToRender, setJsxToRender] = useState<{ [key: string]: JSX.Element | null }>({});

  // The screenshotJobs state is used to store the container elements for each screenshot job
  // The key is the screenshot ID and the value is the container element
  type ScreenshotJobs = { [key: string]: HTMLDivElement | null };
  const [screenshotJobs, setScreenshotJobs] = useState<ScreenshotJobs>({});
  const screenshotJobsRef = useRef(screenshotJobs); // Ref to hold the latest screenshotJobs

  // Keep the ref updated with the latest state
  useEffect(() => {
    screenshotJobsRef.current = screenshotJobs;
  }, [screenshotJobs]);

  // The renderContentForScreenshots function is used to render JSX content in a hidden container
  // These container elements are added to the screenshotJobs state with the screenshot ID as the key
  // Screenshots can then be taken of the content using the takeScreenshots function
  const renderContentForScreenshots = (options: RenderContentOptions) => {
    const { jsx, width = "100%", height = "100%", query = {}, isImageValid = true } = options;

    // Dynamically create and store a new container element for each screenshot job
    const newElement = document.createElement("div");

    newElement.className =
      " ScreenshotContainer invisible-container screenshot-job-" + options.screenshotId;
    Object.assign(newElement.style, { width, height });
    document.body.appendChild(newElement);

    // Update jsxToRender to render the JSX inside the new element
    if (jsx) {
      flushSync(() => {
        // Store the new element in screenshotJobs state
        setScreenshotJobs((prevJobs) => ({
          ...prevJobs,
          [options.screenshotId]: newElement,
        }));

        setJsxToRender((prevJsx) => ({
          ...prevJsx,
          [options.screenshotId]: (
            <ScreenshotJobContext.Provider
              value={{ isAnimationActive: false, query, isImageValid }}
            >
              {jsx}
            </ScreenshotJobContext.Provider>
          ),
        }));
      });
    }
  };

  // The takeScreenshots function is used to take screenshots of the content rendered by renderContentForScreenshots
  // The function takes a screenshotId and an array of querySelectors as input
  // The querySelectors are used to select the elements to take screenshots of
  // The function returns a promise that resolves to an object with the querySelectors as keys containing the screenshots in base64 format
  const takeScreenshots = async (
    screenShotId: string,
    querySelectors: string[],
  ): Promise<{ [key: string]: string }> => {
    const screenshots: { [key: string]: string } = {};

    const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    // Wait for lower screenshot jobs to finish
    while (
      Object.keys(screenshotJobsRef.current).some(
        (id) => parseInt(id, 10) < parseInt(screenShotId, 10),
      )
    ) {
      await wait(1000);
    }

    // Use the ref to get the latest screenshotJobs
    const jobContainer = screenshotJobsRef.current[screenShotId];

    if (!jobContainer) {
      throw new Error("Container not available for screenshots");
    }

    await Promise.all(
      querySelectors.map(async (selector) => {
        const element = jobContainer.querySelector(selector);

        if (element) {
          const imageBlob = await domtoimage.toPng(element);

          screenshots[selector] = imageBlob;
        }
      }),
    );

    setJsxToRender((prevJsx) => {
      const updatedJsx = { ...prevJsx };

      delete updatedJsx[screenShotId]; // Remove the key entry for this screenshot ID

      return updatedJsx;
    });

    const childToRemove = document.querySelector(`.screenshot-job-${screenShotId}`);

    if (childToRemove && childToRemove.parentNode) {
      childToRemove.parentNode.removeChild(childToRemove);
    }

    setScreenshotJobs((prevJobs) => {
      const updatedJobs = { ...prevJobs };

      delete updatedJobs[screenShotId]; // Remove the screenshot job ref

      return updatedJobs;
    });

    return screenshots;
  };

  return (
    <ScreenshotContext.Provider
      value={{
        renderContentForScreenshots,
        takeScreenshots,
      }}
    >
      {children}
      {Object.keys(screenshotJobs).map(
        (id) =>
          jsxToRender[id] &&
          screenshotJobs[id] &&
          createPortal(jsxToRender[id], screenshotJobs[id]),
      )}
    </ScreenshotContext.Provider>
  );
};

const withScreenshotContextProvider = (Component: (props: any) => JSX.Element) => {
  return (props: PropsWithChildren) => (
    <ScreenshotContextProvider>
      <Component {...props} />
    </ScreenshotContextProvider>
  );
};

export {
  ScreenshotContextProvider,
  withScreenshotContextProvider,
  ScreenshotContext,
  useScreenshotJobContext,
};
export default useScreenshotContext;
