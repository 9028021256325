import type { PropertyRegulatoryOrdinance } from "@/Api/generated";

import { Collection } from "resourcerer";

export default class RegulatoryOrdinancesCollection extends Collection<PropertyRegulatoryOrdinance> {
  url({ propertyToken }: { propertyToken: string }) {
    return `/api/property/${propertyToken}/property-regulatory-ordinance`;
  }

  static idAttribute = "token";

  static dependencies = ["propertyToken"];
}
