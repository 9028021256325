import { Collection } from "resourcerer";

import { type CustomSpaceMeterAssignment } from "@/Api/generated";

export interface SpaceMeterAssignment {
  prorata_level: "UNIT" | "PROPERTY";
  meter_id: number;
  unit_tokens: string[];
}

export default class SpaceMeterAssignmentsCollection extends Collection<CustomSpaceMeterAssignment> {
  parse(response: { assignments: CustomSpaceMeterAssignment[] }) {
    return response.assignments;
  }

  url() {
    return "/api/space_meter_assignments/smas_for_self_serve_assignment";
  }

  static idAttribute = "meter_id";

  static dependencies = ["property_token"];
}
