/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Air Source Heat Pump` - Air Source
 * * `Water Source Heat Pump` - Water Source
 * * `Hybrid Air Source Heat Pump with Electric Backup` - Hybrid Air Source
 */
export enum HeaterElectricSpecificHeatPumpTypeEnum {
    AIR_SOURCE_HEAT_PUMP = 'Air Source Heat Pump',
    WATER_SOURCE_HEAT_PUMP = 'Water Source Heat Pump',
    HYBRID_AIR_SOURCE_HEAT_PUMP_WITH_ELECTRIC_BACKUP = 'Hybrid Air Source Heat Pump with Electric Backup',
}
