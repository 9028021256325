/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `ANNUALLY` - Annually
 */
export enum SubmissionFrequencyEnum {
    ANNUALLY = 'ANNUALLY',
}
