/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Water` - Water
 * * `Glycol Mixture` - Glycol
 * * `Other` - Other
 */
export enum HydronicLoopFluidTypeEnum {
    WATER = 'Water',
    GLYCOL_MIXTURE = 'Glycol Mixture',
    OTHER = 'Other',
}
