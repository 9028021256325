import { Collection } from "resourcerer";

import { AssetClass } from "@/Api/generated";

export default class AssetClassesCollection extends Collection<AssetClass> {
  static comparator = "display_text";

  url() {
    return "/api/asset-class";
  }
}
