import { classnames } from "@/js/utils/cambio";

/**
 * Simple message to convey things are missing. Often used in the ErrorState varietal when a
 * request errors.
 */
export default function EmptyState({
  className = "",
  message,
}: {
  className?: string;
  message: string;
}) {
  return (
    <div className={classnames("EmptyState", className)}>
      <p>{message}</p>
    </div>
  );
}

export function ErrorState({ message = "An error occurred." }: { message?: string }) {
  return <EmptyState className="ErrorState" message={message} />;
}
