/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `CARBON_EMISSION_CALCULATION` - Carbon Emission Calculation
 * * `CONSUMPTION_DATA` - Consumption Data
 * * `METER_ASSIGNMENT_DATA` - Meter Assignment Data
 * * `CARBON_FACTORS` - Carbon Factors
 * * `ONBOARDING_DOCUMENT` - Onboarding Document
 * * `RENT_ROLL` - Rent Roll
 * * `ACT_II_INFORMATION` - Act Ii Information
 */
export enum UsageTypeEnum {
    CARBON_EMISSION_CALCULATION = 'CARBON_EMISSION_CALCULATION',
    CONSUMPTION_DATA = 'CONSUMPTION_DATA',
    METER_ASSIGNMENT_DATA = 'METER_ASSIGNMENT_DATA',
    CARBON_FACTORS = 'CARBON_FACTORS',
    ONBOARDING_DOCUMENT = 'ONBOARDING_DOCUMENT',
    RENT_ROLL = 'RENT_ROLL',
    ACT_II_INFORMATION = 'ACT_II_INFORMATION',
}
