/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `MONTHLY` - MONTHLY
 * * `QUARTERLY` - QUARTERLY
 * * `ANNUALLY` - ANNUALLY
 */
export enum FrequencyEnum {
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    ANNUALLY = 'ANNUALLY',
}
