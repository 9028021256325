/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `None` - None
 * * `Air-side` - Air Side
 * * `Water-side` - Water Side
 */
export enum CoolerEconomizerTypeEnum {
    NONE = 'None',
    AIR_SIDE = 'Air-side',
    WATER_SIDE = 'Water-side',
}
