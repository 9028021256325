import Portal from "@/components/Portal";
import { type NotificationState } from "../NotificationContext";
import Notification from "@/components/Notification";
import { useTransition } from "@react-spring/web";

/**
 * Like the ModalSequenceManager, this component is just in charge of animating in and out each
 * Notification.
 */
export default function NotificationCenter(props: NotificationState) {
  const transitions = useTransition(props.notifications, {
    from: { opacity: 0, translateY: 40 },
    enter: { opacity: 1, translateY: 0 },
    leave: { opacity: 0, translateY: -40 },
  });

  return (
    <Portal visible={!!props.notifications.length}>
      <output className="NotificationCenter">
        <ul>
          {transitions((style, notif) => (
            <Notification key={notif.id} style={style} {...notif} />
          ))}
        </ul>
      </output>
    </Portal>
  );
}
