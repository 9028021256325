import type { WeatherDataResponse } from "@/Api/generated";

import { Model } from "resourcerer";

export default class WeatherDataModel extends Model<WeatherDataResponse> {
  url() {
    return "/api/weather/monthly-mean-temperature";
  }

  static dependencies = ["space_token", "start_date", "end_date"];
}
