/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `asphalt` - Asphalt
 * * `slate` - Slate
 * * `ceramic` - Ceramic
 * * `solar` - Solar
 * * `cedar` - Cedar
 * * `other` - Other
 */
export enum RoofShingleTypeEnum {
    ASPHALT = 'asphalt',
    SLATE = 'slate',
    CERAMIC = 'ceramic',
    SOLAR = 'solar',
    CEDAR = 'cedar',
    OTHER = 'other',
}
