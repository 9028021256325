/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Coefficient of Performance` - Cop
 * * `Energy Efficiency Ratio` - Eer
 * * `Seasonal Energy Efficiency Ratio` - Seer
 * * `Annual Fuel Utilization Efficiency` - Afue
 * * `Integrated Energy Efficiency Ratio` - Ieer
 * * `KW/ton` - Kw Per Ton
 */
export enum EfficiencyUnitsEnum {
    COEFFICIENT_OF_PERFORMANCE = 'Coefficient of Performance',
    ENERGY_EFFICIENCY_RATIO = 'Energy Efficiency Ratio',
    SEASONAL_ENERGY_EFFICIENCY_RATIO = 'Seasonal Energy Efficiency Ratio',
    ANNUAL_FUEL_UTILIZATION_EFFICIENCY = 'Annual Fuel Utilization Efficiency',
    INTEGRATED_ENERGY_EFFICIENCY_RATIO = 'Integrated Energy Efficiency Ratio',
    KW_TON = 'KW/ton',
}
