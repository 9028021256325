/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `ON_TRACK` - On Track
 */
export enum Status269Enum {
    ON_TRACK = 'ON_TRACK',
}
