/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `INDUSTRIAL_WAREHOUSE` - Industrial Warehouse
 * * `OFFICE` - Office
 * * `MULTIFAMILY` - Multifamily
 */
export enum AssetClassEnum {
    INDUSTRIAL_WAREHOUSE = 'INDUSTRIAL_WAREHOUSE',
    OFFICE = 'OFFICE',
    MULTIFAMILY = 'MULTIFAMILY',
}
