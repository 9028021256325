/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Fuel Fired` - Fuel Fired
 * * `Electric Resistance` - Electric Resistance
 * * `Heat Pump` - Heat Pump
 * * `Hot Water Coil` - Hot Water Coil
 * * `Steam Heating Coil` - Steam Heating Coil
 */
export enum HeaterHeatingTypeEnum {
    FUEL_FIRED = 'Fuel Fired',
    ELECTRIC_RESISTANCE = 'Electric Resistance',
    HEAT_PUMP = 'Heat Pump',
    HOT_WATER_COIL = 'Hot Water Coil',
    STEAM_HEATING_COIL = 'Steam Heating Coil',
}
