/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `STANDING_ASSET` - Standing Asset
 * * `MAJOR_RENOVATION_PROJECT` - Major Renovation Project
 * * `NEW_DEVELOPMENT` - New Development
 */
export enum ConstructionStatusEnum {
    STANDING_ASSET = 'STANDING_ASSET',
    MAJOR_RENOVATION_PROJECT = 'MAJOR_RENOVATION_PROJECT',
    NEW_DEVELOPMENT = 'NEW_DEVELOPMENT',
}
