/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Grid` - Grid
 * * `Onsite Solar` - Onsite Solar
 * * `Onsite Wind` - Onsite Wind
 * * `Natural Gas` - Natural Gas
 * * `Propane` - Propane
 * * `Fuel Oil` - Fuel Oil
 * * `Steam` - Steam
 * * `Hot Water` - Hot Water
 */
export enum UtilitySourcesSubcategoryEnum {
    GRID = 'Grid',
    ONSITE_SOLAR = 'Onsite Solar',
    ONSITE_WIND = 'Onsite Wind',
    NATURAL_GAS = 'Natural Gas',
    PROPANE = 'Propane',
    FUEL_OIL = 'Fuel Oil',
    STEAM = 'Steam',
    HOT_WATER = 'Hot Water',
}
