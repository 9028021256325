import { useEffect } from "react";

export default function useStorageEvent(key: string, cb: (evt: StorageEvent) => void): void {
  useEffect(() => {
    const onStorageEvent = (evt: StorageEvent) => {
      if (evt.key === key) {
        cb(evt);
      }
    };

    window.addEventListener("storage", onStorageEvent);

    return () => window.removeEventListener("storage", onStorageEvent);
  }, []);
}
