import uniqueId from "lodash/uniqueId";
import { createContext, PropsWithChildren, useMemo, useReducer } from "react";

import { type Notification } from "../";
import NotificationCenter from "../NotificationCenter";

export type NotificationState = { notifications: Array<Notification> };
type NotificationAction =
  | { type: "add"; payload: Notification }
  | { type: "remove"; payload?: { id: Notification["id"] } };

type NotificationContext = {
  notify: (text: string, options?: Partial<Omit<Notification, "text" | "id">>) => void;
  clear: (id: string) => void;
};

const NotificationContext = createContext<NotificationContext>({} as NotificationContext);

export const notificationReducer = (state: NotificationState, action: NotificationAction) => {
  switch (action.type) {
    case "add":
      return { notifications: state.notifications.concat(action.payload) };
    case "remove":
      return { notifications: state.notifications.filter(({ id }) => id !== action.payload.id) };
  }
};

/**
 * HOC that provides its wrapped component with Notification Context and the Notification Center.
 * Add this to any layout, page, or individual component to get easy access to launching
 * notifications:
 *
 * const {notify} = useNotificationContext();
 *
 * onClick={() => notify("Some message")}
 *
 * Note that the AppLayout has this, so everything rendered inside it will already have access.
 */
export const withNotificationContextProvider =
  (Component: (props: any) => JSX.Element) => (props: PropsWithChildren) => {
    const [state, dispatch] = useReducer(notificationReducer, { notifications: [] });

    const value: NotificationContext = useMemo(
      () => ({
        notify: (text, options = {}) => {
          dispatch({ type: "add", payload: { text, ...options, id: uniqueId() } });
        },
        clear: (id) => dispatch({ type: "remove", payload: { id } }),
      }),
      [],
    );

    return (
      <NotificationContext.Provider value={value}>
        <NotificationCenter {...state} />
        <Component {...props} />
      </NotificationContext.Provider>
    );
  };

export default NotificationContext;
